import {atom} from 'recoil';

export type AppliedFilters = {
  price?: {from?: number; to?: number};
  resale?: boolean;
};

export const appliedFiltersAtom = atom<AppliedFilters>({
  key: 'appliedFilters',
  default: {
    price: undefined,
    resale: false,
  },
});
