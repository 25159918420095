import React, {FC, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';

import {deleteTmpCartFromStorage} from '../atoms/tmpCartSelector';
import {useAuth} from '../contexts/Auth';
import {categoriesWithImagesSelector} from '../selectors/categoriesSelector';
import cartsService from '../services/cartsService';
import CategoryCard from './CategoryCard';
import GridBox from './GridBox';

const Categories: FC = () => {
  const {user} = useAuth();
  const categories = useRecoilValue(categoriesWithImagesSelector);
  const [tmpCart, setTmpCart] = useState<any[]>();

  useEffect(() => {
    setTmpCart(JSON.parse(localStorage.getItem('nonLoggedCart')!));
  }, []);

  useEffect(() => {
    const insertProductsToCart = async () => {
      if (tmpCart && tmpCart.length > 0) {
        tmpCart!.forEach(
          async (productId) => await cartsService.insertProductToCurrentUserCart(productId),
        );
      }

      await deleteTmpCartFromStorage();
    };

    if (user) insertProductsToCart();
  }, [tmpCart]);

  return (
    <>
      <div className='pt-8 px-11 text-xl'>Kategorie wählen</div>
      <GridBox
        content={categories.map((cat) => (
          <CategoryCard key={cat.id} categoryId={cat.id} name={cat.name} image={cat.image_url} />
        ))}
      />
    </>
  );
};

export default Categories;
