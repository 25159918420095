import React, {useEffect, useState} from 'react';
import {useRecoilRefresher_UNSTABLE as useRecoilRefresher, useRecoilValue} from 'recoil';

import {IonContent} from '@ionic/react';

import {currentPathUrl, currentProduct, updateImg, updateProduct} from 'atoms/currentProductAtom';
import {imagesToDeleteAtom} from 'atoms/imagesToDeleteAtom';
import IonLoadingSuspense from 'components/IonLoadingSuspense';
import {useAuth} from 'contexts/Auth';
import EditProductForm from 'forms/Products/EditProduct.form';
import useToast from 'hooks/customToast';
import {getUserProductsSelector} from 'selectors/productsSelectors';
import {supabase} from 'supabaseClient';

type EditProps = {
  productId: string;
};

const EditProductPage = ({productId}: EditProps) => {
  const {user} = useAuth();
  const presentToast = useToast();

  const imagesToDelete = useRecoilValue(imagesToDeleteAtom);
  const refreshEditProduct = useRecoilRefresher(currentProduct(productId));
  const refreshImageProduct = useRecoilRefresher(currentPathUrl(productId));
  const refreshMyProducts = useRecoilRefresher(getUserProductsSelector(user?.id));

  const [data, setData] = useState<object>();
  const [images, setImages] = useState<object>();

  // pierdoli, że batcher nie może być zaktualizowany,
  // coś z useEffectem w const powinno być
  useRecoilValue(updateProduct({data, productId}));
  useRecoilValue(updateImg({images, productId})); // bez useRecoilValue te linijki sie nie wykonuja,
  // problem pojawia sie gdy zapytania do bazy sa chyba w zlej kolejnosci, wtedy zadna linijka
  // wewnatrz selektora updateImg nie wykonuje sie i to wlasnie wtedy obrazy sie nie zapisuja

  // zeby zreplikowac blad, otworz strone swojego produktu, i nawet bez zmian kliknij zapisz
  // blad polega na tym ze jest jakas szansa ze po prostu usunie zdjecia (linijka 54)
  // i nie wstawi nowych (linijka 30)
  // wstawienie updateImg wewnatrz editProduct po setImages tez nic nie robi

  useEffect(() => {
    refreshImageProduct();
  }, []);

  const editProduct = async (data: any) => {
    const dataInfo = {...data, owner_id: user?.id};
    const productData = data;
    setData(productData);
    // eslint-disable-next-line camelcase
    const {product_url} = dataInfo;

    if (data.product_url.length == 0) {
      presentToast('Sie müssen ein Foto hinzufügen', 'error');
      return;
    }

    if (data.sale_price >= data.price) {
      presentToast('Der Verkaufspreis muss niedriger sein als der aktuelle Preis', 'error');
      return;
    }

    if (data.sale_price <= 0) {
      presentToast('Der Verkaufspreis muss höher als 0 sein', 'error');
      return;
    }

    if (data.sale_price) {
      data.is_on_sale = true;
      data.old_price = data.price;
      data.price = data.sale_price;
    } else data.is_on_sale = false;

    const {error: deleteError} = await supabase.rpc('delete_product_data', {pid: productId});

    if (deleteError) {
      presentToast(deleteError.message, 'error');
      return;
    }

    const colorsData = data.colors.map((color: number) => ({
      product_id: productId,
      color_id: color,
    }));
    const {error: colorsError} = await supabase.from('products_colors').insert(colorsData);

    if (colorsError) {
      presentToast(colorsError.message, 'error');
      return;
    }

    const sizesData = data.sizes.map((size: number) => ({
      product_id: productId,
      size_id: size,
    }));
    const {error: sizesError} = await supabase.from('products_sizes').insert(sizesData);

    if (sizesError) {
      presentToast(sizesError.message, 'error');
      return;
    }

    const materialsData = data.materials.map((material: number) => ({
      product_id: productId,
      material_id: material,
    }));
    const {error: materialsError} = await supabase.from('products_materials').insert(materialsData);

    if (materialsError) {
      presentToast(materialsError.message, 'error');
      return;
    }

    const {error} = await supabase.from('products_images').delete().eq('product_id', productId);
    if (error) {
      presentToast(error.message, 'error');
    }

    // Najpierw usuwa obrazy z bazy danych, zeby nie bylo konfliktow przy dodawaniu
    // w nastepnym wywolaniu selektora updateImg bo to insert a nie upsert? Tak strzelam
    // usunięcie tego delete i zmiana w selektorze na upsert nie wyczerupuje sprawy

    // eslint-disable-next-line camelcase
    [product_url].map(async (images: any) => {
      setImages(images);
    });

    supabase.storage.from('products').remove(imagesToDelete);

    refreshEditProduct();
    refreshMyProducts();
    history.back();
    presentToast('Produkt erfolgreich bearbeitet!');
  };

  return (
    <IonContent>
      <IonLoadingSuspense>
        <EditProductForm onSubmit={editProduct} />
      </IonLoadingSuspense>
    </IonContent>
  );
};
export default EditProductPage;
