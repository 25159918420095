import {atom} from 'recoil';

export type FilterDataMultipleSelect = {
  color?: {id: number[]};
  size?: {id: number[]};
  material?: {id: number[]};
  price?: {from?: number; to?: number};
  resale?: boolean;
};

export const filterDataAtom = atom<FilterDataMultipleSelect>({
  key: 'filterData',
  default: {
    color: undefined,
    size: undefined,
    material: undefined,
    price: undefined,
    resale: false,
  },
});
