import React, {FC, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';

import {choosenSubcategoryIdAtom} from 'atoms/choosenSubCategoryIdAtom';
import {useAuth} from 'contexts/Auth';
import {getUserProductsSelector, loggedUserProducts} from 'selectors/productsSelectors';
import ProductsList from '../Products.list';

export const MyProductsList: FC = () => {
  const {user} = useAuth();

  const [selectedProducts, setSelectedProducts] = useState<any>([]);

  const products = useRecoilValue(loggedUserProducts(user?.id));
  const allProducts = useRecoilValue(getUserProductsSelector(user?.id));
  const subcategory = useRecoilValue(choosenSubcategoryIdAtom);

  useEffect(() => {
    const updatedProducts = subcategory === 46 ? allProducts : products;
    setSelectedProducts(updatedProducts);
  }, [subcategory]);

  return <ProductsList products={selectedProducts} />;
};
