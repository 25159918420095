import React from 'react';

import {IonItem, IonText} from '@ionic/react';

import {useAuth} from '../../contexts/Auth';

interface LogOutButtonProps {}

export const LogOutButton: React.FC<LogOutButtonProps> = ({}) => {
  const auth = useAuth();
  return (
    <IonItem onClick={auth.signOut} button detail={false} className='bg-none'>
      <IonText className='text-center w-full text-red-600 text-xl py-5'>Abmelden</IonText>
    </IonItem>
  );
};
