import React, {FC} from 'react';
import {isIOS} from 'react-device-detect';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {IonButton, IonContent, IonHeader, IonIcon, IonModal, IonPage} from '@ionic/react';

import {displayedFiltersAtom, FilterData} from '../../atoms/displayedFiltersAtom';
import {filterDataAtom} from '../../atoms/filterDataAtom';
import {isFirstModalOpenedAtom} from '../../atoms/filterModalAtom';
import {colors, materials, sizes} from '../../atoms/selectProductAtom';
import AppToolbar from '../AppToolbar';
import ClearFiltersButton from './ClearFiltersButton';
import FilterModalOption from './FilterModalOption';
import FilterMultipleSelect from './FilterMultipleSelect';
import FilterResaleOption from './FilterResaleOption';

import {closeOutline} from 'ionicons/icons';
import styles from './FilterModal.module.css';

type FilterModalProps = {};

const FilterModal: FC<FilterModalProps> = () => {
  const [isFirstModalOpened, setIsFirstModalOpened] = useRecoilState(isFirstModalOpenedAtom);

  const colorsData = useRecoilValue(colors);
  const sizesData = useRecoilValue(sizes);
  const materialsData = useRecoilValue(materials);
  const [selectedFilters, setSelectedFilters] = useRecoilState(filterDataAtom);

  const setDisplayedFilters = useSetRecoilState(displayedFiltersAtom);

  const hideModal = () => {
    setIsFirstModalOpened(false);
  };

  const handleShowResults = () => {
    let colorFilter = [];
    if (selectedFilters.color?.id) {
      const colorIds = selectedFilters.color.id;
      const colorFilter2 = colorsData.body!.filter((item) => colorIds.includes(item.id));
      colorFilter = colorFilter2.map((item) => item.name);
    }

    let sizeFilter = [];
    if (selectedFilters.size?.id) {
      const sizeIds = selectedFilters.size.id;
      const sizeFilter2 = sizesData.body!.filter((item) => sizeIds.includes(item.id));
      sizeFilter = sizeFilter2.map((item) => item.name);
    }

    let materialFilter = [];
    if (selectedFilters.material?.id) {
      const materialIds = selectedFilters.material.id;
      const materialFilter2 = materialsData.body!.filter((item) => materialIds.includes(item.id));
      materialFilter = materialFilter2.map((item) => item.name);
    }

    const filterData: FilterData = {
      color: colorFilter,
      size: sizeFilter,
      material: materialFilter,
      price: selectedFilters.price,
      resale: selectedFilters.resale,
    };

    setDisplayedFilters(filterData);
    setIsFirstModalOpened(false);
  };

  const getPriceFilterText = () => {
    if (!selectedFilters.price) {
      return 'Alle';
    }
    return `${selectedFilters.price.from} - ${selectedFilters.price.to}`;
  };

  return (
    <IonModal mode='ios' id='filter-modal' isOpen={isFirstModalOpened} onDidDismiss={hideModal}>
      <IonPage>
        <IonHeader
          collapse='condense'
          className={`${styles.Header} ${isIOS && 'pt-[25px] bg-primary'}`}
        >
          <AppToolbar
            leftButtonsCss='z-50'
            leftSlot1={
              <IonIcon onClick={hideModal} className='text-4xl pl-4' icon={closeOutline} />
            }
            titleText='Filter'
            rightSlot1={<ClearFiltersButton />}
          />
        </IonHeader>
        <IonContent scrollY={false} color='tertiary' className='relative'>
          <FilterMultipleSelect
            label='Größe'
            data={sizesData.data}
            item={selectedFilters.size?.id}
            onFilterChange={(newFilter: any) => {
              setSelectedFilters({
                ...selectedFilters,
                size: newFilter,
              });
            }}
          />
          <FilterMultipleSelect
            label='Farbe'
            data={colorsData.data}
            item={selectedFilters.color?.id}
            onFilterChange={(newFilter: any) => {
              setSelectedFilters({
                ...selectedFilters,
                color: newFilter,
              });
            }}
          />
          <FilterMultipleSelect
            label='Material'
            data={materialsData.data}
            item={selectedFilters.material?.id}
            onFilterChange={(newFilter: any) => {
              setSelectedFilters({
                ...selectedFilters,
                material: newFilter,
              });
            }}
          />
          <FilterModalOption type='price' value={getPriceFilterText()} />
          <FilterResaleOption />
          <IonButton
            onClick={handleShowResults}
            className='absolute bottom-8 w-full px-3 h-12 text-white'
          >
            Ergebnisse anzeigen
          </IonButton>
        </IonContent>
      </IonPage>
    </IonModal>
  );
};

export default FilterModal;
