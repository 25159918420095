import React, {useRef} from 'react';

import {IonContent, IonModal, useIonRouter} from '@ionic/react';

import Button from '../../components/Buttons/Button';
import Logo from '../../components/Logo';
import {useAuth} from '../../contexts/Auth';

type ModalProps = {
  isOpen: boolean;
  onDidDismiss: () => void;
};

const UserAddressModal: React.FC<ModalProps> = ({isOpen, onDidDismiss}) => {
  const {user} = useAuth();
  const modal = useRef<HTMLIonModalElement>(null);
  const router = useIonRouter();

  const confirmHandler = () => {
    if (user?.id === undefined) {
      modal.current?.dismiss();
      router.push('/login');
      return;
    }
    router.push('/profile');
    modal.current?.dismiss();
  };

  const cancelHandler = () => {
    modal.current?.dismiss();
    onDidDismiss();
  };

  return (
    <IonModal ref={modal} isOpen={isOpen}>
      <IonContent className='ion-padding'>
        <div className='flex h-[30%] desktop:h-[40%] justify-center'>
          <Logo />
        </div>
        <div className='flex h-[25%] mx-auto items-center text-center text-xl desktop:w-[60%]'>
          Wenn du bei more.moda etwas kaufen möchtest, dann brauchen wir zuerst deine Adresse. Der
          Verkäufer möchte ja wissen, wo er das Paket hinschicken soll.
        </div>
        <div className='h-[15%]' />
        <div className='flex justify-center'>
          <Button text='Weiter' onClick={confirmHandler}></Button>
          <Button text='Abbrechen' onClick={cancelHandler}></Button>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default UserAddressModal;
