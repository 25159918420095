import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';

import {IonCard, IonImg, IonRouterLink, IonText} from '@ionic/react';

import {getProductDetailsSelector} from '../../selectors/productsSelectors';

type ConversationProductInfoProps = {
  productId: string;
};

export const ConversationProductInfo: FC<ConversationProductInfoProps> = ({productId}) => {
  const {id, name, images, price, size} = useRecoilValue(getProductDetailsSelector(productId));

  return (
    <IonCard
      color='dark'
      className='h-14 tablet:h-28 desktop:h-36 pt-0 my-0 shadow-lg mx-0 px-0
      rounded-none flex justify-between items-center'
    >
      <div className='flex items-center space-x-3 pl-3'>
        {images.length > 0 ? (
          <IonImg
            className='w-12 h-12 tablet:w-24 tablet:h-24 desktop:w-32 desktop:h-32 rounded-xl'
            src={images && images[0].url}
          />
        ) : (
          <img
            className='w-12 h-12 tablet:w-24 tablet:h-24 desktop:w-32 desktop:h-32 rounded-xl'
            alt='placeholder'
            src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
          />
        )}
        <div className='flex flex-col tablet:text-lg desktop:text-xl'>
          <IonText>{name}</IonText>
          <div
            className='flex flex-row space-x-2 font-light text-gray-100 text-xs
            tablet:text-base desktop:text-lg'
          >
            <IonText>Preis: {price} €</IonText>
            <IonText>Größe: {size}</IonText>
          </div>
        </div>
      </div>
      <IonRouterLink
        routerLink={`/details/${id}`}
        routerDirection='forward'
        className='underline pr-3 tablet:pr-4 tablet:text-base desktop:pr-5 desktop:text-lg'
        color='primary'
      >
        Produkt anzeigen
      </IonRouterLink>
    </IonCard>
  );
};
