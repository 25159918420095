import {supabase} from '../supabaseClient';

const insertProductToCurrentUserCart = async (productId: number) => {
  const {error} = await supabase.rpc('insert_product_to_cart', {
    product_id_input: productId,
  });

  if (error) {
    throw error.message;
  }
};

const insertProductsToCurrentUserCart = async (productId: number[]) => {
  const {error} = await supabase.rpc('insert_products_to_cart', {
    product_ids_input: productId,
  });

  if (error) {
    throw error.message;
  }
};

const removeProductFromCurrentUserCart = async (productId: number) => {
  const {error} = await supabase.rpc('remove_product_from_cart', {
    product_id_input: productId,
  });

  if (error) {
    throw error.message;
  }
};

const removeAllProductsFromCurrentUserCart = async () => {
  const {error} = await supabase.rpc('remove_all_products_from_user_cart');

  if (error) {
    throw error.message;
  }
};

export default {
  insertProductToCurrentUserCart,
  insertProductsToCurrentUserCart,
  removeProductFromCurrentUserCart,
  removeAllProductsFromCurrentUserCart,
};
