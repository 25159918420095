import React, {FC} from 'react';

import BIonForm from '../components/BIonForm';
import PasswordInput from '../components/PasswordInput';
import TextInput from '../components/TextInput/TextInput';
import BaseFormProps from '../interfaces/BaseFormProps';
import {FormField} from '../interfaces/FormField';
import {SignInData} from '../interfaces/SignInData';

const SignInForm: FC<BaseFormProps<SignInData>> = ({onSubmit}) => {
  const fields: FormField[] = [
    {
      name: 'email',
      component: <TextInput label='Email' />,
      options: {required: 'Dieses Feld ist erforderlich!'},
    },
    {
      name: 'password',
      component: <PasswordInput type='password' label='Password' />,
      options: {required: 'Dieses Feld ist erforderlich!'},
    },
  ];

  return (
    <BIonForm
      fields={fields}
      submitBtnText='Einloggen'
      onSubmit={onSubmit}
      customCss='mx-auto w-full tablet:w-3/5 desktop:w-3/5'
      type='login'
    />
  );
};

export default SignInForm;
