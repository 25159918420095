import {atom, selector} from 'recoil';

import {CartProductData} from '../interfaces/CartProductData';
import {supabase} from '../supabaseClient';

export const cartActionsCount = atom({key: 'cartActionsCount', default: 0});

export const cartProductsQuantitySelector = selector({
  key: 'cartProductsQuantity',
  get: ({get}) => get(getCartProducts).length,
});

export const cartProductsQuantity = atom({
  key: 'cartProducts',
  default: 0,
});

export const getCartProducts = selector({
  key: 'getCurrentUserCart',
  get: async ({get}) => {
    get(cartActionsCount);

    const {data, error} = await supabase.rpc('get_current_user_cart_products').select(
      `
      id,
      name,
      price,
      description,
      is_sold,
      is_on_sale,
      image_url,
      stripe_account_id
      `,
    );

    if (error) {
      throw error.message;
    }

    return data.map((x) => {
      return {
        id: x!.id,
        name: x.name,
        price: x.price,
        description: x.description,
        isSold: x.is_sold,
        isOnSale: x.is_on_sale,
        firstProductImageUrl: x.image_url,
        stripeOwnerId: x.stripe_account_id,
      } as CartProductData;
    });
  },
});
