import React, {FC, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {IonCard, IonImg, useIonRouter} from '@ionic/react';

import {choosenCategoryIdAtom} from '../atoms/choosenCategoryIdAtom';
import {useAuth} from '../contexts/Auth';
import {getUserAgeConsent} from '../selectors/userSelectors';
import AgeConsentModal from './AgeConsentModal';

type CategoryCardProps = {
  image: string;
  name: string;
  categoryId: number;
};

const CategoryCard: FC<CategoryCardProps> = ({name, image, categoryId}) => {
  const {user} = useAuth();
  const router = useIonRouter();

  const setChosenCategoryId = useSetRecoilState(choosenCategoryIdAtom);
  const ageConsent = useRecoilValue(getUserAgeConsent(user?.id));
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleCardClick = () => {
    if (
      categoryId === 6 &&
      ((ageConsent && ageConsent.age_consent === false) || user?.id === undefined)
    ) {
      setModalOpen(true);
      return;
    }
    router.push('/products');
    setChosenCategoryId(categoryId);
  };

  return (
    <>
      <IonCard
        onClick={handleCardClick}
        color='primary'
        className='h-40 desktop:h-64 rounded-xl flex flex-col m-1.5 cursor-pointer'
      >
        <div className='h-4/5 flex justify-center items-center'>
          <IonImg className='w-24 desktop:w-40' src={image} alt='category icon' />
        </div>
        <div className='flex justify-center items-center bg-white h-1/4'>{name}</div>
      </IonCard>
      <AgeConsentModal isOpen={modalOpen} />
    </>
  );
};

export default CategoryCard;
