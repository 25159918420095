import React from 'react';

type Props = {content: Object};

const GridBox: React.FC<Props> = ({content}) => {
  return (
    <div className='w-full flex justify-center my-5 mobile:px-2'>
      <div className='grid grid-cols-2 w-full tablet:w-2/3 desktop:w-1/3'>{content}</div>
    </div>
  );
};

export default GridBox;
