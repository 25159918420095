import {atom, selectorFamily} from 'recoil';

import CurrentBlob from '../interfaces/CurrentBlobImagesData';
import {supabase} from '../supabaseClient';

export const prodId = atom<number | null>({
  key: 'productId',
  default: null,
});

export const currentValueLengthImg = atom<number>({
  key: 'currentValueLengthImg',
  default: 0,
});

export const currentProduct = selectorFamily<object, string>({
  key: 'currentProduct',
  get: (prodId) => async () => {
    const {data: productInfo, error: productError} = await supabase
      .from('products')
      .select('name, price, size, material, category, color, description, user_id, subcategory')
      .eq('id', prodId)
      .single();

    if (productError) {
      throw productError.message;
    }

    return productInfo;
  },
});

export const currentPathUrl = selectorFamily<any, string>({
  key: 'currentimage',
  get: (prodId) => async () => {
    const {data: imageInfo, error: imageError} = await supabase
      .from('products_images')
      .select('image_url, id')
      .eq('product_id', prodId);

    if (imageError) {
      throw imageError.message;
    }

    return imageInfo;
  },
});

export const currentBlobImages = selectorFamily<any, any>({
  key: 'currentBlobImages',
  get: (imagePath) => async () => {
    const filePath = imagePath.map((image: CurrentBlob) => {
      return image.image_url;
    });

    const imageBlobs = await Promise.all(
      filePath.map(async (file: string) => {
        return [await supabase.storage.from('public/products').download(file), file];
      }),
    );

    return imageBlobs;
  },
});

export const currentBlobImage = selectorFamily<any, any>({
  key: 'currentBlobImages',
  get: (imagePath) => async () => {
    return [await supabase.storage.from('public/avatars').download(imagePath), imagePath];
  },
});

export const updateProduct = selectorFamily<any, any>({
  key: 'updateProduct',
  get: (productData) => async () => {
    const response = await supabase
      .from('products')
      .update([
        {
          category: productData.data?.category,
          color: productData.data?.color,
          description: productData.data?.description,
          material: productData.data?.material,
          name: productData.data?.name,
          price: productData.data?.price,
          size: productData.data?.size,
          subcategory: productData.data?.subcategory,
          is_on_sale: productData.data?.is_on_sale,
          old_price: productData.data?.old_price,
        },
      ])
      .eq('id', productData.productId);

    return response;
  },
});

export const updateImg = selectorFamily<any, any>({
  key: 'updateProduct',
  get: (imagesData) => async () => {
    const response = imagesData.images?.forEach(async (image: any) => {
      await supabase.storage.from('products').upload(image.filePath, image.file);
    });

    const fetchUrl = async (url: string) => {
      await supabase
        .from('products_images')
        .insert([{image_url: url, product_id: imagesData?.productId}]);
    };

    imagesData.images?.forEach((image: any) => {
      fetchUrl(image[1] || image.filePath);
    });

    if (imagesData.images) {
      await supabase
        .from('products')
        .update({
          custom_text_1: imagesData.images[0][1],
        })
        .eq('id', imagesData.productId);
    }
    return response;
  },
});
