import {useIonToast} from '@ionic/react';

const useToast = () => {
  const [present] = useIonToast();

  return (message: string, mode: 'error' | 'success' | 'warning' = 'success') => {
    if (mode === 'error') {
      present({
        color: 'danger',
        cssClass: 'text-center',
        message: message,
        duration: 3000,
      });
    } else {
      present({
        color: mode,
        cssClass: 'text-center',
        message: message,
        duration: 2000,
      });
    }
  };
};

export default useToast;
