import React, {FC} from 'react';
import {useParams} from 'react-router';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {currentCategoryId} from '../../atoms/categoryValueAtom';
import {currentBlobImages, currentPathUrl} from '../../atoms/currentProductAtom';
import {categories, colors, materials, sizes, subCategories} from '../../atoms/selectProductAtom';
import {UIFields} from '../../components/UIFields';
import BaseFormProps from '../../interfaces/BaseFormProps';
import {getProductDetails} from '../../selectors/productsSelectors';

const EditProductForm: FC<BaseFormProps<any>> = ({onSubmit}) => {
  const {productId}: any = useParams();
  const productInfo = useRecoilValue(getProductDetails(productId));

  const subCategoriesData: any = useRecoilValue(subCategories);
  const sizesData = useRecoilValue(sizes);
  const materialsData = useRecoilValue(materials);
  const categoriesData = useRecoilValue(categories);
  const colorsData = useRecoilValue(colors);
  const imageUrl = useRecoilValue(currentPathUrl(productId));
  const imagesBlobs = useRecoilValue(currentBlobImages(imageUrl));
  const sendDataProduct = {product_url: imagesBlobs, ...productInfo};
  const categoryId = useSetRecoilState(currentCategoryId);
  categoryId(productInfo.category);

  return (
    <UIFields
      onSubmit={onSubmit}
      subCategoriesData={subCategoriesData}
      sizesData={sizesData}
      materialsData={materialsData}
      categoriesData={categoriesData}
      colorsData={colorsData}
      defaultValues={sendDataProduct}
      addOrEditLabel='Speichern'
    />
  );
};
export default EditProductForm;
