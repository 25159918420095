import React from 'react';

import logoIcon from '../static/logo_icon.svg';

interface LogoSignProps {
  width?: number;
  height?: number;
}

const LogoSign: React.FC<LogoSignProps> = ({width = 75.12, height}) => {
  return <img alt='logo' src={logoIcon} width={width} height={height} />;
};

export default LogoSign;
