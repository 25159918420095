import React, {ComponentProps, FC, useEffect, useState} from 'react';
import {useController} from 'react-hook-form';
import {useRecoilState} from 'recoil';

import {IonItem, IonLabel, IonNote, IonSelect, IonSelectOption} from '@ionic/react';

import {previewProductDataAtom} from '../../atoms/previewProductDataAtom';
import {BaseInputProps} from '../../interfaces/BaseInputsProps';
import {supabase} from '../../supabaseClient';

type SelectProps = {
  label: string;
  type?: 'text' | 'password';
  item: any;
} & BaseInputProps &
  ComponentProps<typeof IonSelect>;

const Select: FC<SelectProps> = ({fieldName = '', label, control, rules, item}: SelectProps) => {
  const {
    field: {name, onChange, onBlur, ref, value},
    fieldState: {error},
  } = useController({
    control,
    name: fieldName,
    rules,
  });

  const [previewData, setPreviewData] = useRecoilState(previewProductDataAtom);
  const [databaseTable, setDatabaseTable] = useState<string>('');

  useEffect(() => {
    if (value === undefined) return;

    const fetchData = async () => {
      const {data, error} = await supabase
        .from(databaseTable)
        .select('name')
        .eq('id', value)
        .single();

      if (error) {
        throw error.message;
      }

      if (name === 'subcategory') setPreviewData({...previewData, [name]: data});
      if (name === 'sizes') setPreviewData({...previewData, [name]: [data]});
    };

    fetchData();
  }, [value]);

  useEffect(() => {
    if (name === 'subcategory') setDatabaseTable('subcategories');
    if (name === 'sizes') setDatabaseTable('sizes');
  }, []);

  return (
    <IonItem
      lines='none'
      className='my-3 mx-auto w-11/12 tablet:w-2/3 desktop:w-2/5 bg-offWhite bg-opacity-25
        rounded-3xl ion-invalid'
    >
      <IonLabel position='floating'>{label}</IonLabel>
      <IonSelect
        mode='ios'
        ref={ref}
        name={name}
        value={value}
        okText='Okay'
        cancelText='Dismiss'
        onIonChange={onChange}
        onIonBlur={onBlur}
        interface='popover'
      >
        {item.map((items: any, numberId: number) => (
          <IonSelectOption key={numberId} value={items.id} className='bg-darkGreen'>
            {items.name}
          </IonSelectOption>
        ))}
      </IonSelect>
      <IonNote slot='error' color='white' className='text-sm tablet:text-base desktop:text-lg'>
        {error && error.message}
      </IonNote>
    </IonItem>
  );
};

export default Select;
