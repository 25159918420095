import {Chat} from 'interfaces/Chat';
import {Message} from 'interfaces/Message';
import {supabase} from 'supabaseClient';
import {BASE_STORAGE_URL} from 'utils/constants';

const getMessages = async (interlocutorId?: string, productId?: number) => {
  if (!interlocutorId || productId == null) {
    return [];
  }

  const {data, error} = await supabase
    .rpc('get_conversation', {
      interlocutor_id: interlocutorId,
      product_id_input: productId,
    })
    .select('*');

  if (error != null) {
    throw error.message;
  }

  return mapReponseToMessages(data);
};

const getChats = async (currentUserId: string) => {
  const {data, error} = await supabase
    .from('chats')
    .select(
      `
      id,
      product(id,name),
      first_participant(user_id, first_name, last_name, avatar_url),
      second_participant(user_id, first_name, last_name, avatar_url),
      messages(id, text, has_been_read, created_at, sender_id)
      `,
    )
    .or(`first_participant.eq.${currentUserId},second_participant.eq.${currentUserId}`)
    .limit(1, {foreignTable: 'messages'})
    .order('created_at', {ascending: false, foreignTable: 'messages'});

  if (error != null) {
    throw error.message;
  }

  const mappedData = data.map((x) => {
    const interlocutor =
      currentUserId === x.first_participant.user_id ? x.second_participant : x.first_participant;

    const avatarUrl = interlocutor.avatar_url;
    const imageUrl = avatarUrl ? `${BASE_STORAGE_URL}/avatars/${avatarUrl}` : '';
    const lastMessage = x.messages[0];

    return {
      id: x.id,
      hasNewMessages: lastMessage && !lastMessage.has_been_read,
      imageUrl: x.avatar_url ? BASE_STORAGE_URL + x.avatar_url : '',
      interlocutor: {
        id: interlocutor.user_id,
        firstName: interlocutor.first_name,
        lastName: interlocutor.last_name,
        imageUrl: imageUrl,
      },
      product: x.product,
      lastMessage: lastMessage?.text,
      lastMessageDate: lastMessage && new Date(lastMessage.created_at),
      isLastMessageSentByCurrentUser: currentUserId === lastMessage?.sender_id,
    } as Chat;
  });

  return mappedData
    .sort((x, y) => (x.lastMessageDate > y.lastMessageDate ? 1 : -1))
    .sort((x, y) => (x.hasNewMessages ? -1 : 1));
};

const sendMessage = async (interlocutorId: string, productId: number, messageText: string) => {
  const {error} = await supabase.rpc('create_new_message', {
    receiver_id_input: interlocutorId,
    product_id_input: productId,
    text_input: messageText,
  });

  if (error != null) {
    throw error.message;
  }
};

export const messagesService = {
  getMessages,
  getChats,
  sendMessage,
};

const mapReponseToMessages = (response: any[]) => {
  return response.map((x) => {
    return {
      id: x.m_id,
      sentByCurrentUser: x.sent_by_current_user,
      sentDate: new Date(x.m_created_at),
      text: x.m_text,
    } as Message;
  });
};
