

import {IonContent, IonPage} from '@ionic/react';

import ButtonLink from '../../components/Buttons/ButtonLink';
import Logo from '../../components/Logo';

const RegisterConfirmed = () => {
  return (
    <IonPage>
      <IonContent>
        <div className='h-9/10 flex flex-col justify-center'>
          <Logo />
          <div className='w-9/12 mx-auto text-center'>
            <p className='font-bold text-2xl pt-16'>Registrierung erfolgreich.</p>
            <p className='items-center pt-10'>
              Sie können nun das Browserfenster schließen und zur MoreModa-Anwendung zurückkehren.
            </p>
          </div>
          <div className='flex justify-center pt-16'>
            <ButtonLink text='Zur App wechseln' routerLink='dashboard'></ButtonLink>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RegisterConfirmed;
