import React from 'react';

import {IonText} from '@ionic/react';

import packageJson from '../../package.json';

export const VersionText: React.FC = () => (
  <div className='flex flex-row w-full justify-around text-xs font-light'>
    <IonText>Version {packageJson.version}</IonText>
    <IonText>Build {packageJson.build}</IonText>
  </div>
);
