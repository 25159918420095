import {selectorFamily} from 'recoil';

import {supabase} from '../supabaseClient';

export const getUserAgeConsent = selectorFamily({
  key: 'getUserAgeConsent',
  get:
    (userId) =>
    async ({}) => {
      if (userId === undefined) return;
      const {data, error} = await supabase
        .from('profiles')
        .select('age_consent')
        .eq('user_id', userId)
        .single();

      if (error != null) {
        throw error.message;
      }

      return data;
    },
});

export const getUserAddressInformation = selectorFamily({
  key: 'getUserAddressInformation',
  get:
    (userId) =>
    async ({}) => {
      if (userId === undefined) return;
      const {data, error} = await supabase
        .from('profiles')
        .select('street_name, house_number, postal_code, city, country')
        .eq('user_id', userId)
        .single();

      if (error != null) {
        throw error.message;
      }

      const checkIfUserProvidedAddressInformation = Object.values(data).every(
        (x: any) => x !== null && x !== undefined && x !== '',
      );

      if (checkIfUserProvidedAddressInformation === true) {
        return {
          streetName: data.street_name,
          houseNumber: data.house_number,
          postalCode: data.postal_code,
          city: data.city,
          country: data.country,
        };
      }
      return -1;
    },
});
