import React, {FC, useState} from 'react';

import {IonIcon, IonItem, IonList} from '@ionic/react';

import {closeOutline} from 'ionicons/icons';

type Props = {
  productId: number;
  productName: string;
  productOwner?: string;
  productDescription: string;
  productPrice: number;
  productImage: string;
  ignoreProduct: (ignoreValue: boolean, productId: number) => void;
  deleteSelectedItem: () => void;
};

const ShoppingCartElement: FC<Props> = ({
  productId,
  productName,
  productDescription,
  productPrice,
  productImage,
  ignoreProduct,
  deleteSelectedItem,
}) => {
  const [checkboxValue, setCheckboxValue] = useState<string>('false');

  const checkboxHandler = (event: any) => {
    setCheckboxValue(event.target.checked.toString());
    ignoreProduct(event.target.checked, productId);
  };

  return (
    <IonList>
      <IonItem lines='none'>
        <div className='flex desktop:w-5/12 w-full mb-2 mx-auto'>
          {productImage ? (
            <img
              alt='product_image'
              src={
                'https://fmnftbssqvgsvwykyfzh.supabase.co/storage/v1/object/public/products/' +
                productImage
              }
              className='w-24 h-24 rounded-xl bg-contain'
            />
          ) : (
            <img
              alt='placeholder'
              src='https://ionicframework.com/docs/img/demos/thumbnail.svg'
              className='rounded-xl w-24 h-24'
            />
          )}
          <div className='desktop:w-[77%] w-[60%] text-sm pl-3'>
            <div className=''>{productName}</div>
            <div className='text-lightGreen h-[68px] line-clamp-3 pt-2'>{productDescription}</div>
          </div>
          <IonIcon
            className='w-12 my-auto text-5xl text-lightGreen mx-auto'
            icon={closeOutline}
            onClick={deleteSelectedItem}
          />
        </div>
      </IonItem>
      <div
        className='desktop:w-[43%] bg-darkGreen border-t border-b-4 border-primary
                      items-center mx-auto'
      >
        <div className='flex h-8 w-[95%] mx-auto items-center '>
          <input type='checkbox' value={checkboxValue} onClick={checkboxHandler} />
          <div className='ml-1'>Später kaufen</div>
          <div className='ml-auto'>Preis: {productPrice}€</div>
        </div>
      </div>
    </IonList>
  );
};

export default ShoppingCartElement;
