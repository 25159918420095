import React, {FC} from 'react';

import Button from '../../components/Buttons/Button';

type ShoppingCartTotalPriceProps = {
  itemsInCart: number;
  totalPrice: number;
  goToPayment: () => void;
};

export const ShoppingCartTotalPrice: FC<ShoppingCartTotalPriceProps> = ({
  itemsInCart,
  totalPrice,
  goToPayment,
}) => {
  return (
    <div className='w-full fixed bottom-0 z-10 desktop:w-3/5 inset-x-0 mx-auto'>
      <div className='flex flex-col space-y-4 py-6 bg-darkGreen'>
        <div className='flex justify-around'>
          <div>
            {itemsInCart > 1
              ? `Ausgewählte ${itemsInCart} Artikel`
              : `Ausgewählte 
            ${itemsInCart} Artikel`}
          </div>
          <div>Insgesamt: {totalPrice}€</div>
        </div>
        <div className='flex justify-center'>
          <Button
            text='Kaufen'
            className='w-[85%] tablet:w-[60%] desktop:w-[55%] capitalize h-12 bg-primary
            rounded-xl shadow-md text-white'
            onClick={goToPayment}
          ></Button>
        </div>
      </div>
    </div>
  );
};
