import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';

import {currentUserProfile} from '../../atoms/currentUserProfileAtom';
import AvatarImageInput from '../../components/AvatarImageInput';
import BIonForm from '../../components/BIonForm';
import TextInput from '../../components/TextInput/TextInput';
import {useAuth} from '../../contexts/Auth';
import BaseFormProps from '../../interfaces/BaseFormProps';
import {FormField} from '../../interfaces/FormField';
import {UserProfile} from '../../interfaces/UserProfile';

const EditProfileForm: FC<BaseFormProps<UserProfile>> = ({onSubmit, children}) => {
  const {user} = useAuth();

  const userProfile = useRecoilValue(currentUserProfile(user!.id));

  const fields: FormField[] = [
    {
      name: 'avatar',
      component: <AvatarImageInput />,
    },
    {
      name: 'first_name',
      component: <TextInput label='Name' maxlength={15} />,
      options: {required: 'Dieses Feld ist erforderlich!'},
    },
    {
      name: 'last_name',
      component: <TextInput label='Nachname' maxlength={15} />,
      options: {required: 'Dieses Feld ist erforderlich!'},
    },
    {
      name: 'street_name',
      component: <TextInput label='Straße' maxlength={40} />,
    },
    {
      name: 'house_number',
      component: <TextInput label='Hausnummer' maxlength={6} />,
    },
    {
      name: 'postal_code',
      component: <TextInput label='PLZ' maxlength={10} />,
    },
    {
      name: 'city',
      component: <TextInput label='Stadt' maxlength={85} />,
    },
    {
      name: 'country',
      component: <TextInput label='Land' maxlength={20} />,
    },
    {
      name: 'phone_number',
      component: <TextInput label='Telefonnummer (optional)' maxlength={12} />,
    },
  ];

  return (
    <div className='pb-10 pt-3'>
      <BIonForm fields={fields} defaultValues={userProfile} onSubmit={onSubmit}>
        {children}
      </BIonForm>
    </div>
  );
};

export default EditProfileForm;
