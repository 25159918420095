import React from 'react';
import {useRecoilState} from 'recoil';

import {IonButton, IonHeader, IonIcon, IonItem, IonList, useIonRouter} from '@ionic/react';

import {useAuth} from '../../contexts/Auth';
import useToast from '../../hooks/customToast';
import {messagesCountAtom} from '../../selectors/messagesSelectors';
import AppToolbar from '../AppToolbar';
import {VersionText} from '../VersionText';
import {BurgerMenuWebItem} from './BurgerMenuWeb.item';

import {
  heartOutline,
  logOutOutline,
  newspaperOutline,
  personOutline,
  serverOutline,
  shirtOutline,
} from 'ionicons/icons';
import activeGoodsIcon from '../../static/BottomTabsBar/active_goods_icon.svg';
import addGoodsIcon from '../../static/BottomTabsBar/add_goods_icon.svg';
import homeIcon from '../../static/BottomTabsBar/home_icon.svg';
import messagesIcon from '../../static/BottomTabsBar/messages_icon.svg';
import saleIcon from '../../static/BottomTabsBar/sale_icon.svg';

export const BurgerMenuWeb: React.FC = () => {
  const auth = useAuth();
  const presentToast = useToast();
  const router = useIonRouter();
  const [messagesCount] = useRecoilState(messagesCountAtom);

  const signOutHandler = () => {
    presentToast('Du hast dich erfolgreich abgemeldet');
    router.push('/dashboard');
    auth.signOut();
  };

  return (
    <div className='flex flex-col h-screen bg-darkGreen justify-between'>
      <IonHeader>
        <AppToolbar titleText='Menü' />
      </IonHeader>
      <IonList inset={true} className='h-full '>
        <BurgerMenuWebItem routerLink='/dashboard' label='Dashboard' icon={homeIcon} />
        <BurgerMenuWebItem routerLink='/add' label='Produkt hinzufügen' icon={addGoodsIcon} />
        <BurgerMenuWebItem routerLink='/myproducts' label='Meine Produkte' icon={activeGoodsIcon} />
        <BurgerMenuWebItem routerLink='/favorite-products' label='Favoriten' icon={heartOutline} />
        <BurgerMenuWebItem
          routerLink='/purchase-sales'
          label='Bereits gekauft/verkauft'
          icon={serverOutline}
        />
        <BurgerMenuWebItem routerLink='/sale' label='Aktionen' icon={saleIcon} />
        <BurgerMenuWebItem routerLink='/profile' label='Mein Profil' icon={personOutline} />
        <BurgerMenuWebItem
          routerLink='/inbox'
          label='Nachrichten'
          icon={messagesIcon}
          messageBadge={messagesCount != 0 && messagesCount}
        />
        <BurgerMenuWebItem routerLink='/legals' label='Rechtsinformation' icon={newspaperOutline} />
        <BurgerMenuWebItem routerLink='/sizes' label='Größen' icon={shirtOutline} />
      </IonList>
      <div className='flex justify-center'>
        <IonItem lines='none' className='w-4/5 justify-center' onClick={signOutHandler}>
          {auth.user !== null && (
            <IonButton fill='clear' className='w-full h-12 rounded-xl bg-primary text-black'>
              <div>Abmelden</div>
              <IonIcon className='text-2xl' icon={logOutOutline} slot='end' />
            </IonButton>
          )}
        </IonItem>
      </div>
      <div className='py-5'>
        <VersionText />
      </div>
    </div>
  );
};
