import {atom} from 'recoil';

export type FilterData = {
  color?: string[];
  size?: string[];
  material?: string[];
  price?: {from?: number; to?: number};
  resale?: boolean;
};

export const displayedFiltersAtom = atom<FilterData>({
  key: 'displayedFilters',
  default: {
    color: undefined,
    size: undefined,
    material: undefined,
    price: undefined,
    resale: false,
  },
});
