import {useRecoilValue} from 'recoil';

import {IonCard, IonText, useIonRouter} from '@ionic/react';

import {FavoritesButton} from 'components/Buttons/FavouriteButton';
import {useAuth} from 'contexts/Auth';
import {Product} from 'interfaces/Product';
import {ShoppingCartButton} from 'pages/Cart/ShoppingCart.button';
import {getCartProducts} from 'selectors/cartSelector';

const OnSaleProductCard = ({
  productId,
  productName,
  price,
  oldPrice,
  sizes,
  isSold,
  firstImageUrl,
  isItMyProduct,
  isFavorited,
  isOnSale,
}: Product) => {
  const {user} = useAuth();
  const router = useIonRouter();
  const cartProducts = useRecoilValue(getCartProducts);
  const discount = 100 - (price / oldPrice) * 100;

  return (
    <IonCard
      className='h-48 desktop:h-64 flex flex-col items-stretch m-1.5 bg-offWhite rounded-2xl
      cursor-pointer'
    >
      <div className='flex'>
        {isOnSale && (
          <div className='static w-full pb-1 bg-pureRed text-center rounded-t-2xl'>
            <div className='mt-1'>
              <IonText
                className='text-white desktop:font-normal font-medium
                desktop:text-lg text-xs'
              >
                Im Abverkauf! -{discount.toFixed(0)}%
              </IonText>
            </div>
          </div>
        )}
      </div>
      {user && !isItMyProduct && !isSold && (
        <ShoppingCartButton
          productId={productId}
          isInCart={cartProducts.some((x) => x.id === productId)}
          isUserAuth={user != null}
          className='absolute left-1 desktop:top-10 top-8
          text-xl tablet:text-2xl desktop:text-3xl z-10'
        />
      )}
      {user && (
        <FavoritesButton
          productId={productId}
          isFavorited={isFavorited}
          className='absolute right-1 desktop:top-10 top-8 text-2xl tablet:text-3xl desktop:text-4xl
          text-primary z-10'
        />
      )}
      <div
        className='h-5/6 relative flex justify-center items-center'
        onClick={() => !isSold && router.push(`/details/${productId}`)}
      >
        {firstImageUrl ? (
          <div
            className='bg-center bg-cover bg-no-repeat h-full w-full'
            style={{
              backgroundImage: `url(${
                'https://fmnftbssqvgsvwykyfzh.supabase.co/storage/v1/object/public/products/' +
                firstImageUrl
              })`,
            }}
          />
        ) : (
          <div
            className='bg-center bg-cover bg-no-repeat h-full w-full'
            style={{
              backgroundImage: `url('https://ionicframework.com/docs/img/demos/thumbnail.svg')`,
            }}
          />
        )}
        <div className='absolute bottom-0 w-full text-center'>
          {isSold && (
            <div className='bg-pureOrange'>
              <IonText className='text-white font-light'>SOLD</IonText>
            </div>
          )}
          <div className={`${isSold ? 'bg-gray-400' : 'bg-gray-900/50'}`}>
            <IonText className='text-white font-light line-clamp-1'>{productName}</IonText>
          </div>
        </div>
      </div>
      <div className='h-1/6 bg-primary flex flex-row items-center justify-around'>
        <IonText className='flex text-white font-light'>
          size:&nbsp;<span className='font-bold'>{sizes.map((size) => size).join(', ')}</span>
        </IonText>
        <IonText className='text-white font-light truncate'>
          price:&nbsp;<span className='font-normal'>{price}€</span>
        </IonText>
      </div>
      {isSold && <div className='absolute w-full h-full bg-white/40 bottom-0' />}
    </IonCard>
  );
};

export default OnSaleProductCard;
