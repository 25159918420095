import React, {FC} from 'react';

import {IonContent, IonHeader, IonPage} from '@ionic/react';

import AppToolbar from '../../components/AppToolbar';
import {ChatsList} from '../../components/Chat/Chats.list';
import IonLoadingSuspense from '../../components/IonLoadingSuspense';

export const Inbox: FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <AppToolbar titleText='Posteingang' />
      </IonHeader>
      <IonContent fullscreen forceOverscroll scrollX={false}>
        <IonLoadingSuspense isOpen={false}>
          <ChatsList />
        </IonLoadingSuspense>
      </IonContent>
    </IonPage>
  );
};
