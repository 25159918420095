import React, {FC, useEffect} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {
  IonBadge,
  IonIcon,
  IonLabel,
  IonMenu,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';

import {isBottomTabBarHiddenAtom} from '../../atoms/bottomTabBarAtom';
import {deviceWidthType} from '../../atoms/deviceWidthType';
import {usePaynamentAuth} from '../../pages/PaymentInfo/PaynamentState';
import {lastestMessageIdFromDbAtom, messagesCountAtom} from '../../selectors/messagesSelectors';
import {supabase} from '../../supabaseClient';
import {BurgerMenuMobile} from '../BurgerMenu/BurgerMenuMobile';
import {BurgerMenuWeb} from '../BurgerMenu/BurgerMenuWeb';
import Routes from '../Routes/Routes';
import {bottomBarTabs} from './BottomBarTabs.element';

const BottomTabBar: FC = () => {
  const {isPaynamentInProgres} = usePaynamentAuth();
  const isBottomTabBarHidden = useRecoilValue(isBottomTabBarHiddenAtom);
  const [deviceWidth, setDeviceWidth] = useRecoilState(deviceWidthType);
  const [messagesCount, setMessagesCount] = useRecoilState(messagesCountAtom);
  const setLastestMessageIdFromDb = useSetRecoilState(lastestMessageIdFromDbAtom);

  const windowWidth = window.innerWidth;

  useEffect(() => {
    const getDeviceWidth = () => {
      if (windowWidth < 768) {
        setDeviceWidth('mobile');
        return;
      }
      if (windowWidth > 768 && windowWidth < 1024) {
        setDeviceWidth('tablet');
        return;
      }
      if (windowWidth > 1024) {
        setDeviceWidth('desktop');
      }
    };

    getDeviceWidth();
  }, []);

  useEffect(() => {
    const getNewMessagesCount = async () => {
      const {data, error} = await supabase.rpc<number>('get_new_messages_count').single();

      if (error != null) {
        throw error.message;
      }

      setMessagesCount(data);
    };

    getNewMessagesCount();

    const subscription = supabase
      .from(`messages`)
      .on('INSERT', (payload: any) => {
        if (!payload?.new?.id) {
          return;
        }
        setLastestMessageIdFromDb(payload.new.id);
        getNewMessagesCount();
      })
      .on('UPDATE', (p) => {
        getNewMessagesCount();
      })
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <IonSplitPane when='md' contentId='router-outlet'>
      <IonMenu disabled={isPaynamentInProgres} contentId='router-outlet'>
        {deviceWidth != 'mobile' ? <BurgerMenuWeb /> : <BurgerMenuMobile />}
      </IonMenu>
      <IonPage id='router-outlet'>
        <IonTabs>
          <IonRouterOutlet id='router-outlet'>
            <Routes />
          </IonRouterOutlet>
          <IonTabBar
            hidden={deviceWidth != 'mobile' ? true : isBottomTabBarHidden}
            mode='ios'
            slot='bottom'
            color='primary'
            className='border-t-1 border-[#576b63]'
          >
            {bottomBarTabs.BOTTOM_BAR_TABS.map((x) => (
              <IonTabButton key={x.id} tab={x.tab} href={`/${x.tab}`}>
                <IonIcon icon={x.icon} />
                <IonLabel>{x.label}</IonLabel>
                {x.tab === bottomBarTabs.MESSAGE_TAB && messagesCount != 0 && (
                  <IonBadge>{messagesCount}</IonBadge>
                )}
              </IonTabButton>
            ))}
          </IonTabBar>
        </IonTabs>
      </IonPage>
    </IonSplitPane>
  );
};

export default BottomTabBar;
