import {Route, Switch} from 'react-router';

import RecoverPassword from '../../pages/Auth/RecoverPassword.page';
import RegisterPromptPage from '../../pages/Auth/RegisterPromptPage';
import NewPassword from '../../pages/Auth/SetPassword.page';
import Login from '../../pages/Auth/SignIn.page';
import Register from '../../pages/Auth/SignUp.page';
import SuccessfullRegister from '../../pages/Auth/SuccessfullRegister.page';
import LegalsPage from '../../pages/Commons/Legals.page';
import PaymentSucces from '../../pages/PaymentInfo/PaymentSucces';
import AllProducts from '../../pages/Products/AllProducts/AllProducts.page';
import AllSellerProducts from '../../pages/Products/AllSelerProducts/AllSellerProducts.page';
import Dasboard from '../../pages/Products/Dashboard.page';
import DetailProduct from '../../pages/Products/DetailsProduct/DetailsProduct.page';
import OnSaleProducts from '../../pages/Products/SaleProducts/SaleProducts.page';
import ProfileTab from '../../pages/ProfileTab/ProfileTab.page';
import SearchPage from '../../pages/Search/Search.page';
import SizesPage from '../../pages/Sizes/Sizes.page';

const PublicRoutes = () => {
  return (
    <Switch>
      <Route path='/register' component={Register} />
      <Route path='/new-password' component={NewPassword} />
      <Route path='/recover' component={RecoverPassword} />

      <Route path='/dashboard' component={Dasboard} />
      <Route path='/products' component={AllProducts} />
      <Route path='/myproducts' component={Login} />
      <Route path='/login' component={Login} />
      <Route path='/seller-products' component={AllSellerProducts} />

      <Route path='/cart' component={RegisterPromptPage} />
      <Route path='/search' component={SearchPage} />
      <Route path='/purchase-sales' component={Login} />
      <Route path='/favorite-products' component={Login} />
      <Route path='/sale' component={OnSaleProducts} />
      <Route path='/profiletab' component={ProfileTab} />
      <Route path='/sizes' component={SizesPage} />

      <Route path='/add' component={RegisterPromptPage} />
      <Route path='/details/:productId' component={DetailProduct} />
      <Route path='/inbox' component={Login} />
      <Route path='/conversation/:interlocutorId/:productId' component={Login} />
      <Route path='/success/:email' component={PaymentSucces} />
      <Route path='/activate' component={SuccessfullRegister} />

      <Route path='/profile' component={Login} />

      <Route path='/changePassword' component={Login} />
      <Route path='/legals' component={LegalsPage} />

      <Route path='/dashboard' component={Dasboard} />
      <Route component={Dasboard} />
    </Switch>
  );
};

export default PublicRoutes;
