export const NUMBER_OF_PRODUCT_PHOTOS = 10;
export const MAX_USER_NAME_LENGTH_AT_INBOX_ITEM = 15;
export const MAX_MESSAGE_PREVIEW_LENGTH_AT_INBOX_ITEM = 45;
export const MAX_INTERLOCUTOR_FULLNAME_AT_CONVERSATION = 25;
export const BASE_STORAGE_URL = 'https://fmnftbssqvgsvwykyfzh.supabase.co/storage/v1/object/public';
export const MAX_PRODUCTS_AMOUNT_ON_SALE_AT_ONCE = 10;

// eslint-disable-next-line
// "If you have any questions or concerns regarding the transaction, please contact the seller directly"
export const SYSTEM_BOUGHT_MESSAGE =
  'Wenn Sie Fragen oder Bedenken bezüglich der Transaktion haben, ' +
  'wenden Sie sich bitte direkt an den Verkäufer';
