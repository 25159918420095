import React, {FC} from 'react';

const PaymentSucces: FC = () => {
  return (
    <div className='text-center pt-28'>
      <p className='text-2xl text-white pb-4'>Bezahlung erfolgreich!</p>
      <p>Wenn Sie die mobile Anwendung verwenden, können Sie dieses Fenster schließen.</p>
    </div>
  );
};

export default PaymentSucces;
