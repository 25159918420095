import './EditProduct.css';

import React, {Suspense} from 'react';
import {useParams} from 'react-router';
import {useRecoilRefresher_UNSTABLE as useRecoilRefresher} from 'recoil';

import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  useIonAlert,
} from '@ionic/react';

import {currentProduct} from '../../../atoms/currentProductAtom';
import AppToolbar from '../../../components/AppToolbar';
import {BackButton} from '../../../components/Buttons/BackButton';
import useToast from '../../../hooks/customToast';
import {supabase} from '../../../supabaseClient';
import EditProductPage from './EditProduct.page';

import {trash} from 'ionicons/icons';

const EditPage = () => {
  const {productId}: any = useParams();
  const presentToast = useToast();
  const [presentAlert] = useIonAlert();

  const refreshEditProduct = useRecoilRefresher(currentProduct(productId));

  // rozwiazanie tymczasowe
  const sleep = (time: any) => new Promise((res) => setTimeout(res, time, '123'));

  const deleteProduct = async () => {
    await supabase.from('products_images').delete().eq('product_id', productId);
    await supabase.from('favorite_products').delete().eq('product_id', productId);
    const {data} = await supabase.from('chats').select('id').eq('product', productId);
    data?.map(async (x) => {
      await supabase.from('messages').delete().eq('chat', x.id);
      await supabase.from('chats').delete().eq('product', productId);
    });

    await sleep(250); // rozwiazanie tymczasowe
    const {error} = await supabase.from('products').delete().eq('id', productId);

    refreshEditProduct();
    history.back();
    if (error) {
      await presentToast('Produkt konnte nicht gelöscht werden', 'error');
      return;
    }
    await presentToast('Produkt erfolgreich gelöscht');
  };

  refreshEditProduct();

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar
          leftSlot1={<BackButton />}
          titleText='Edit'
          rightSlot1={
            <IonButton
              onClick={() =>
                presentAlert({
                  header: 'Sind Sie sicher, dass Sie dieses Produkt löschen wollen?',
                  mode: 'md',
                  cssClass: 'custom-alert',
                  buttons: [
                    {
                      text: 'Abbrechen',
                      cssClass: 'alert-button-cancel',
                    },
                    {
                      text: 'Löschen',
                      cssClass: 'alert-button-confirm',
                      handler: () => deleteProduct(),
                    },
                  ],
                })
              }
            >
              <IonIcon
                icon={trash}
                color='danger'
                className='text-2xl tablet:text-3xl desktop:text-4xl'
              />
            </IonButton>
          }
        />
      </IonHeader>
      <IonContent>
        <Suspense fallback={<IonLoading isOpen={true} />}>
          <EditProductPage productId={productId} />
        </Suspense>
      </IonContent>
    </IonPage>
  );
};
export default EditPage;
