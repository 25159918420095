import {selectorFamily} from 'recoil';

import {UserProfile} from '../interfaces/UserProfile';
import {supabase} from '../supabaseClient';

export const currentUserProfile = selectorFamily<UserProfile, string>({
  key: 'currentUserProfileSelector',
  get:
    (userId) =>
    async ({}) => {
      const {data: userProfile, error: profileError} = await supabase
        .from('profiles')
        .select(
          `first_name, last_name, nick_name, avatar_url, charges_enabled, stripe_account_id,
          street_name, house_number, postal_code, phone_number, city, country`,
        )
        .eq('user_id', userId)
        .single();
      if (profileError) {
        throw profileError.message;
      }

      const {avatar_url: avatarUrl, ...restProfile} = userProfile;
      if (avatarUrl == null) {
        return restProfile;
      }

      const {data: avatar, error: avatarError} = await supabase.storage
        .from('public/avatars')
        .download(avatarUrl);

      if (avatarError) {
        throw avatarError.message;
      }
      return {avatar: {file: avatar}, avatarUrl, ...restProfile};
    },
});
