import {atom, selector, selectorFamily} from 'recoil';

import {PostgrestFilterBuilder} from '@supabase/postgrest-js';

import {choosenCategoryIdAtom} from '../atoms/choosenCategoryIdAtom';
import {choosenSubcategoryIdAtom} from '../atoms/choosenSubCategoryIdAtom';
import {displayedFiltersAtom, FilterData} from '../atoms/displayedFiltersAtom';
import {ownerProductsAtom} from '../atoms/ownerProductsAtom';
import {ConversationProductInfo} from '../interfaces/ConversationProductInfo';
import {Product} from '../interfaces/Product';
import {supabase} from '../supabaseClient';
import {BASE_STORAGE_URL} from '../utils/constants';

export const productsActionsCount = atom({key: 'productsActionsCount', default: 0});

export const productOwnerNameSelector = selectorFamily({
  key: 'getProductOwnerName',
  get:
    (productId) =>
    async ({}) => {
      const {data, error} = await supabase
        .from('products')
        .select(`owner_id(nick_name)`)
        .eq('id', productId);
      if (error != null) {
        throw error.message;
      }

      return data;
    },
});

export const productsSelector = selector<Product[]>({
  key: 'products',
  get: async ({get}) => {
    get(productsActionsCount);

    const {data, error} = await supabase.from<Product>('products').select('*');
    if (error != null) {
      throw error.message;
    }

    return data;
  },
});

// WOLNE: Kazde wywolanie tej funkcji dodaje ~250ms do ladowania strony
export const productFirstProductPhotoSelector = selectorFamily({
  key: 'firstProductPhoto',
  get:
    (productId) =>
    async ({}) => {
      const {data, error} = await supabase
        .from('products_images')
        .select('image_url')
        .eq('product_id', productId);
      if (error != null) {
        throw error.message;
      }
      if (data == null || data.length === 0) {
        return;
      }

      const {data: photoUrl, error: storageError} = await supabase.storage
        .from('products')
        .getPublicUrl(data[0].image_url);
      if (storageError != null) {
        throw storageError.message;
      }
      if (photoUrl == null) {
        return;
      }

      return photoUrl.publicURL;
    },
});

export const allProducts = selector({
  key: 'allProducts',
  get: async ({get}) => {
    get(productsActionsCount);

    const choosenSubcategoryId = get(choosenSubcategoryIdAtom);
    if (choosenSubcategoryId == null) {
      return;
    }
    const appliedFilters = get(displayedFiltersAtom);
    const query = supabase
      .rpc('get_all_products2')
      .select(
        `
        id,
        name,
        price,
        old_price,
        size(id, name),
        color(id, name),
        material(id, name),
        subcategory,
        resale,
        user_id,
        is_sold,
        is_on_sale,
        is_in_cart,
        is_it_my_product,
        is_favorited,
        colors,
        sizes,
        materials,
        custom_text_1
        `,
      )
      .eq('subcategory', choosenSubcategoryId)
      .eq('is_sold', false);

    const queryWithFilters = filterQuery(query, appliedFilters);

    const {data, error} = await queryWithFilters;
    if (error != null) {
      throw error.message;
    }

    const dataToReturn = data.map((x) => {
      return {
        isOnSale: x.is_on_sale,
        isSold: x.is_sold,
        description: x.description,
        price: x.price,
        oldPrice: x.old_price,
        subcategory: x.subcategory,
        createdAt: x.created_at,
        productName: x.name,
        productId: x.id,
        firstImageUrl: x.custom_text_1,
        isItMyProduct: x.is_it_my_product,
        isFavorited: x.is_favorited,
        colors: x.colors,
        sizes: x.sizes,
        materials: x.materials,
      } as Product;
    });
    return dataToReturn;
  },
});

export const allProductsByCategory = selector({
  key: 'allProductsByCategory',
  get: async ({get}) => {
    get(productsActionsCount);

    const chosenCategoryId = get(choosenCategoryIdAtom);
    if (chosenCategoryId == null) {
      return;
    }

    const appliedFilters = get(displayedFiltersAtom);

    const query = supabase
      .rpc('get_all_products2')
      .select(
        `
        id,
        name,
        price,
        category,
        subcategory,
        resale,
        user_id,
        is_sold,
        is_on_sale,
        is_in_cart,
        is_it_my_product,
        is_favorited,
        colors,
        sizes,
        materials,
        old_price,
        custom_text_1
        `,
      )
      .eq('category', chosenCategoryId)
      .eq('is_sold', false);

    const queryWithFilters = await filterQuery(query, appliedFilters);

    const {data, error} = await queryWithFilters;
    if (error != null) {
      throw error.message;
    }

    const dataToReturn = data.map((x) => {
      return {
        isOnSale: x.is_on_sale,
        isSold: x.is_sold,
        description: x.description,
        price: x.price,
        subcategory: x.subcategory,
        createdAt: x.created_at,
        productName: x.name,
        productId: x.id,
        firstImageUrl: x.custom_text_1,
        isItMyProduct: x.is_it_my_product,
        isFavorited: x.is_favorited,
        colors: x.colors,
        sizes: x.sizes,
        materials: x.materials,
        oldPrice: x.old_price,
      } as Product;
    });
    return dataToReturn;
  },
});

export const getProductDetailsSelector = selectorFamily<ConversationProductInfo, string>({
  key: 'getProductDetailsSelector',
  get:
    (productId) =>
    async ({get}) => {
      get(productsActionsCount);

      const {data, error} = await supabase
        .from('products')
        .select('id, name, price, size(name), products_images(id,image_url)')
        .eq('id', productId)
        .single();

      if (error) {
        throw error.message;
      }

      return {
        id: data.id,
        name: data.name,
        images: data.products_images.map((x: any) => {
          return {id: x.id, url: `${BASE_STORAGE_URL}/products/${x.image_url}`};
        }),
        size: data.size?.name,
        price: data.price,
      } as ConversationProductInfo;
    },
});

export const allLoggedUserProductsOnSale = selector({
  key: 'allLoggedUserProductsOnSale',
  get: async () => {
    const {data, error} = await supabase
      .rpc('get_current_user_products')
      .select('*')
      .eq('is_sold', false);

    if (error != null) {
      throw error.message;
    }

    return data;
  },
});

export const getUserProductsSelector = selectorFamily({
  key: 'getUserProductsSelector',
  get:
    (userId) =>
    async ({get}) => {
      const {data, error} = await supabase
        .rpc('get_all_products2')
        .select('products')
        .select('*')
        .eq('owner_id', userId)
        .eq('is_sold', false);

      if (error != null) {
        throw error.message;
      }

      return data.map((x) => {
        return {
          productId: x.id,
          ownerId: x.owner_id,
          productName: x.productName,
          category: x.category,
          subcategory: x.subcategory,
          price: x.price,
          oldPrice: x.old_price,
          description: x.description,
          firstImageUrl: x.custom_text_1,
          isSold: x.is_sold,
          isOnSale: x.is_on_sale,
          isFavorited: x.is_favorited,
          colors: x.colors,
          sizes: x.sizes,
          materials: x.materials,
        } as Product;
      });
    },
});

export const getOwnerProductsSelector = selector({
  key: 'getOwnerProductsSelector',
  get: async ({get}) => {
    const ownerId = get(ownerProductsAtom);
    if (ownerId === null) return [];

    const {data, error} = await supabase
      .rpc('get_all_products2')
      .select('*')
      .eq('owner_id', ownerId)
      .eq('is_sold', false);

    if (error) throw error.message;

    return data.map((x) => {
      return {
        productId: x.id,
        ownerId: x.owner_id,
        productName: x.name,
        category: x.category,
        subcategory: x.subcategory,
        price: x.price,
        description: x.description,
        colors: x.colors,
        sizes: x.sizes,
        materials: x.materials,
        oldPrice: x.old_price,
        firstImageUrl: x.custom_text_1,
        isSold: x.is_sold,
        isOnSale: x.is_on_sale,
        isFavorited: x.is_favorited,
      } as Product;
    });
  },
});

export const loggedUserProducts = selectorFamily({
  key: 'loggedUserProducts',
  get:
    (userId) =>
    async ({get}) => {
      get(productsActionsCount);

      const choosenSubcategoryId = get(choosenSubcategoryIdAtom);
      if (choosenSubcategoryId == null) {
        return;
      }

      const appliedFilters = get(displayedFiltersAtom);

      const query = supabase
        .rpc('get_all_products2')
        .select(
          `
        id,
        name,
        price,
        size(id, name),
        color,
        material,
        price,
        subcategory,
        user_id,
        is_sold,
        is_on_sale,
        is_in_cart,
        is_favorited,
        colors,
        sizes,
        materials,
        custom_text_1
        `,
        )
        .eq('subcategory', choosenSubcategoryId)
        .eq('user_id', userId)
        .eq('is_sold', false);

      const queryWithFilters = filterQuery(query, appliedFilters);

      const {data, error} = await queryWithFilters;

      if (error != null) {
        throw error.message;
      }

      const dataToReturn = data.map((x) => {
        return {
          isOnSale: x.is_on_sale,
          isSold: x.is_sold,
          description: x.description,
          price: x.price,
          subcategory: x.subcategory,
          createdAt: x.created_at,
          productName: x.name,
          productId: x.id,
          firstImageUrl: x.custom_text_1,
          isItMyProduct: true,
          isFavorited: x.is_favorited,
          colors: x.colors,
          sizes: x.sizes,
          materials: x.materials,
        } as Product;
      });
      return dataToReturn;
    },
});

const filterQuery = (query: PostgrestFilterBuilder<any>, filters: FilterData) => {
  const {size, price, material, color, resale} = filters;
  if (color && color.length > 0) query.overlaps('colors', color);
  if (size && size.length > 0) query.overlaps('sizes', size);
  if (material && material.length > 0) query.overlaps('materials', material);
  if (price) query.gte('price', price.from).lte('price', price.to);
  if (resale) query.eq('resale', true);

  return query;
};

export const getProductDetails = selectorFamily({
  key: 'getProductDetails',
  get:
    (productId) =>
    async ({}) => {
      const {data, error} = await supabase.rpc('get_product_details', {pid: productId}).single();
      if (error != null) {
        throw error.message;
      }

      return data;
    },
});

export const getProductDetailsTEXT = selectorFamily({
  key: 'getProductDetails',
  get:
    (productId) =>
    async ({}) => {
      const {data, error} = await supabase.rpc('get_product_detailstext', {pid: productId});
      if (error != null) {
        throw error.message;
      }

      const {data: additionalProductDetails, error: productError} = await supabase
        .from('products')
        .select('category(name), subcategory(name)')
        .eq('id', productId);

      if (productError) {
        throw productError.message;
      }

      const combinedObject = [
        {
          ...(data?.[0] ?? {}),
          ...(additionalProductDetails?.[0] ?? {}),
        },
      ];

      return combinedObject;
    },
});
