import React from 'react';

import {IonBackButton} from '@ionic/react';

type Props = {
  text?: string;
  mode?: 'ios' | 'md';
  defaultHref?: string;
  customCss?: string;
};

export const BackButton: React.FC<Props> = ({
  text = '',
  mode = 'ios',
  defaultHref = '/',
  customCss,
}) => <IonBackButton mode={mode} defaultHref={defaultHref} text={text} className={customCss} />;
