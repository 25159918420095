import React, {FC, useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';

import {IonChip, IonLabel, IonToolbar} from '@ionic/react';

import {choosenSubcategoryIdAtom} from '../atoms/choosenSubCategoryIdAtom';
import {subcategoriesOfChoosenCategorySelector} from '../selectors/subcategoriesSelectors';
import IonLoadingSuspense from './IonLoadingSuspense';

const HeaderSubCategoriesItems: FC = () => {
  const subCategories = useRecoilValue(subcategoriesOfChoosenCategorySelector);
  const [choosenSubCategoryId, setChoosenSubCategoryId] = useRecoilState(choosenSubcategoryIdAtom);

  const handleIonChipClick = (subCategoryId: number) => {
    setChoosenSubCategoryId(subCategoryId);
  };

  useEffect(() => {
    if (subCategories == null || subCategories.length === 0) {
      setChoosenSubCategoryId(null);
      return;
    }

    setChoosenSubCategoryId(0);
  }, [subCategories]);

  return (
    <div
      className='flex overflow-x-auto w-full py-1 tablet:py-1.5 tablet:px-2
      desktop:py-2 desktop:px-2'
    >
      <IonChip
        key={0}
        className={`border-1 flex-shrink-0 border-white pointer-events-auto ${
          choosenSubCategoryId === 0 ? 'bg-primary text-white' : ''
        }`}
        onClick={() => handleIonChipClick(0)}
        disabled={choosenSubCategoryId !== 0}
      >
        <IonLabel>Alle</IonLabel>
      </IonChip>

      {subCategories &&
        subCategories.map((x) => (
          <IonChip
            key={x.id}
            className={`border-1 flex-shrink-0 border-white pointer-events-auto ${
              choosenSubCategoryId === x.id ? 'bg-primary text-white' : ''
            }`}
            onClick={() => handleIonChipClick(x.id)}
            disabled={choosenSubCategoryId !== x.id}
          >
            <IonLabel>{x.name}</IonLabel>
          </IonChip>
        ))}
    </div>
  );
};

const HeaderSubCategories: FC = () => {
  return (
    <IonToolbar color='primary' className='h-14'>
      <IonLoadingSuspense>
        <>
          <div className='w-full h-[1px] bg-white' />
          <HeaderSubCategoriesItems />
        </>
      </IonLoadingSuspense>
    </IonToolbar>
  );
};

export default HeaderSubCategories;
