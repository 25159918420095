import {atom, useRecoilState} from 'recoil';

export const isPaynamentProgres = atom({
  key: 'isPaynamentProgres',
  default: false,
});

export const usePaynamentAuth = () => {
  const [isPaynamentInProgres, setIsPaynamentInProgres] = useRecoilState(isPaynamentProgres);
  const setPaynamentAuth = () => {
    const newValue = !isPaynamentInProgres;
    setIsPaynamentInProgres(newValue);
  };

  return {isPaynamentInProgres, setPaynamentAuth};
};
