import React from 'react';

import {IonButtons, IonTitle, IonToolbar} from '@ionic/react';

import logoHeader from '../static/logo_header_beta.svg';

type Props = {
  centerChild?: Object;
  titleText?: string;
  leftSlot1?: Object;
  leftSlot2?: Object;
  leftSlot3?: Object;
  leftSlot4?: Object;
  leftButtonsCss?: string;
  rightSlot1?: Object;
  rightSlot2?: Object;
  rightSlot3?: Object;
  rightSlot4?: Object;
  rightButtonsCss?: string;
};

const AppToolbar: React.FC<Props> = ({
  centerChild,
  titleText,
  leftSlot1,
  leftSlot2,
  leftSlot3,
  leftSlot4,
  leftButtonsCss,
  rightSlot1,
  rightSlot2,
  rightSlot3,
  rightSlot4,
  rightButtonsCss,
}) => (
  <IonToolbar mode='ios' color='primary'>
    <IonButtons
      slot='start'
      className={
        leftButtonsCss
          ? leftButtonsCss
          : 'mobile:ml-1 mobile:-space-x-0.5 tablet:ml-2 desktop:space-x-3 desktop:ml-5'
      }
    >
      {leftSlot1}
      {leftSlot2}
      {leftSlot3}
      {leftSlot4}
    </IonButtons>
    {titleText != null ? (
      <IonTitle className='font-normal tablet:text-xl desktop:text-xl'>{titleText}</IonTitle>
    ) : (
      <>
        {centerChild != null ? (
          centerChild
        ) : (
          <div className='absolute w-full left-0 bottom-1 tablet:bottom-3 desktop:bottom-4'>
            <img
              alt='header logo text'
              src={logoHeader}
              className='w-[135px] tablet:w-[220px] desktop:w-[250px] mx-auto inset-x-0 pb-0.5'
            />
          </div>
        )}
      </>
    )}
    <IonButtons
      slot='end'
      className={
        rightButtonsCss
          ? rightButtonsCss
          : 'mobile:mr-1 mobile:-space-x-0.5 tablet:mr-2 desktop:space-x-3 desktop:mr-5'
      }
    >
      {rightSlot1}
      {rightSlot2}
      {rightSlot3}
      {rightSlot4}
    </IonButtons>
  </IonToolbar>
);

export default AppToolbar;
