import React from 'react';

import {IonItem, IonLabel, IonSelect, IonSelectOption} from '@ionic/react';

type FilterMultipleSelectProps = {
  label: string;
  data: any;
  item?: number[];
  onFilterChange: any;
};

const FilterMultipleSelect: React.FC<FilterMultipleSelectProps> = ({
  label,
  data,
  item,
  onFilterChange,
}) => {
  const onChange = (e: any) => {
    onFilterChange({id: e.target.value});
  };
  return (
    <IonItem
      lines='none'
      className='my-3 mx-auto w-11/12 tablet:w-4/5 desktop:w-4/5 bg-offWhite bg-opacity-25
    rounded-3xl ion-invalid'
    >
      <IonLabel>{label}</IonLabel>
      <IonSelect mode='ios' value={item} onIonChange={onChange} interface='popover' multiple={true}>
        {data &&
          data.map((items: any, numberId: number) => (
            <IonSelectOption key={numberId} value={items.id} className='bg-darkGreen'>
              {items.name}
            </IonSelectOption>
          ))}
      </IonSelect>
    </IonItem>
  );
};

{
}

export default FilterMultipleSelect;
