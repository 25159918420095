import {ReactNode, Suspense} from 'react';

import {IonLoading} from '@ionic/react';

type Props = {
  isOpen?: boolean;
  children: ReactNode;
};

const IonLoadingSuspense = ({isOpen = true, children}: Props) => (
  <Suspense fallback={<IonLoading isOpen={isOpen} />}>{children}</Suspense>
);

export default IonLoadingSuspense;
