import React from 'react';

import {useIonRouter} from '@ionic/react';

import LogoText from './AppTitle';
import LogoSign from './LogoSign';

interface LogoProps {
  iconWidth?: number;
  textWidth?: number;
}

const Logo: React.FC<LogoProps> = ({iconWidth = 75.12, textWidth}) => {
  const router = useIonRouter();

  return (
    <div
      className='flex flex-col justify-center items-center mb-5 space-y-3'
      onClick={() => {
        router.push('/');
      }}
    >
      <LogoSign width={iconWidth} />
      <LogoText width={textWidth} />
    </div>
  );
};

export default Logo;
