import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useSetRecoilState} from 'recoil';

import {User} from '@supabase/supabase-js';

import {currentUserId} from '../atoms/currentUserIdAtom';
import {AuthContextValue} from '../interfaces/AuthContextValue';
import {SignInData} from '../interfaces/SignInData';
import {SignUpData} from '../interfaces/SignUpData';
import {supabase} from '../supabaseClient';

export const AuthContext = React.createContext<AuthContextValue>({} as AuthContextValue);

export const AuthProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const router = useHistory();
  const setCurrentUser = useSetRecoilState(currentUserId);

  useEffect(() => {
    const session = supabase.auth.session();
    setUser(session?.user ?? null);
    setCurrentUser(session?.user?.id ?? null);
    setLoading(false);

    const query = new URLSearchParams(router.location.hash.substring(1));
    const isRecovery = query.get('type') === 'recovery';
    if (isRecovery) {
      supabase.auth.signOut().then((_) => {
        router.push({
          pathname: '/new-password',
          search: '?access_token=' + query.get('access_token'),
        });
      });
      return;
    }

    const {data: listener} = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_IN' || event === 'SIGNED_OUT') {
        setUser(session?.user ?? null);
        setLoading(false);
      }
    });

    return () => {
      listener?.unsubscribe();
    };
  }, []);

  const value: AuthContextValue = {
    signUp: (signUpData: SignUpData) =>
      supabase.auth.signUp(signUpData, {
        data: {
          first_name: signUpData.firstName,
          last_name: signUpData.lastName,
          nick_name: signUpData.nickName,
          email: signUpData.email,
        },
      }),
    signIn: (signInData: SignInData) => supabase.auth.signIn(signInData),
    signOut: () => supabase.auth.signOut(),
    recoverPassword: (email: string) =>
      supabase.auth.api.resetPasswordForEmail(email, {
        redirectTo: 'http://localhost:3000/new-password/',
      }),
    user: user,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextValue => {
  return useContext(AuthContext);
};
