import React from 'react';
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch';

import {IonContent, IonHeader, IonPage} from '@ionic/react';

import AppToolbar from '../../components/AppToolbar';

import sizesBoots from '../../static/sizes_boots.svg';
import sizesHats from '../../static/sizes_hats.svg';
import sizesPants from '../../static/sizes_pants.svg';

const SizesPage: React.FC = () => {
  let isDesktopWidth = false;
  if (window.innerWidth >= 1024) {
    isDesktopWidth = true;
  }

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar />
      </IonHeader>
      <IonContent>
        <div className='w-full flex justify-center'>
          <TransformWrapper
            panning={{disabled: true}}
            wheel={{disabled: true}}
            doubleClick={{disabled: isDesktopWidth}}
          >
            <TransformComponent>
              <div className='grid grid-cols-1 gap-4'>
                <img alt='pants' src={sizesPants} className='mx-auto' />
                <img alt='hats' src={sizesHats} className='mx-auto' />
                <img alt='boots' src={sizesBoots} className='mx-auto' />
                <img
                  alt='shirts'
                  src={require('../../static/sizes_shirts.png')}
                  className='mx-auto'
                />
              </div>
            </TransformComponent>
          </TransformWrapper>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SizesPage;
