import React, {FC} from 'react';

import {IonContent, IonHeader, IonMenuButton, IonPage} from '@ionic/react';

import AppToolbar from '../../components/AppToolbar';
import {CartButton} from '../../components/Buttons/CartButton';
import {SearchButton} from '../../components/Buttons/SearchButton';
import Categories from '../../components/Categories';
import IonLoadingSuspense from '../../components/IonLoadingSuspense';

const Dasboard: FC = () => (
  <IonPage>
    <IonHeader>
      <AppToolbar
        leftSlot1={<IonMenuButton />}
        rightSlot2={<SearchButton />}
        rightSlot3={<CartButton />}
      />
    </IonHeader>
    <IonContent className='mb-20'>
      <IonLoadingSuspense>
        <Categories />
      </IonLoadingSuspense>
    </IonContent>
  </IonPage>
);

export default Dasboard;
