import React, {FC, useEffect, useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';

import _ from 'lodash';
import {choosenSubcategoryIdAtom} from '../../../atoms/choosenSubCategoryIdAtom';
import {FavoriteProduct} from '../../../interfaces/FavoriteProduct';
import {
  favoritesSelector,
  getUserFavoritesProductsSelector,
} from '../../../selectors/favoritesSelector';
import FavoritesProductsElement from './FavoritesProducts.element';
import {FavoritesProductPopUp} from './FavoritesProducts.popup';

export const FavoritesProductsList: FC = () => {
  const favorites = useRecoilValue(favoritesSelector);

  const allFavoriteProducts = useRecoilValue(getUserFavoritesProductsSelector);
  const subcategory = useRecoilValue(choosenSubcategoryIdAtom);

  const [selectedProducts, setSelectedProducts] = useState<FavoriteProduct[]>([]);
  const [allSelectedProducts, setAllSelectedProducts] = useState<FavoriteProduct[]>([]);

  useEffect(() => {
    if (subcategory === 46) {
      setAllSelectedProducts(allFavoriteProducts);
    } else {
      setAllSelectedProducts(favorites);
    }
  }, [subcategory, allFavoriteProducts, favorites]);

  const selectedProductsTotalPrice = useMemo(
    () => _.sumBy(selectedProducts, (x) => x.price || 0),
    [selectedProducts],
  );

  const handleProductChecked = (productId: number) => {
    setSelectedProducts((prevValues) => {
      const checkedProduct = allSelectedProducts.find((x) => x.productId === productId);
      if (checkedProduct && !prevValues.includes(checkedProduct)) {
        return [...prevValues, checkedProduct];
      }
      return prevValues;
    });
  };

  const renderFavoritesProducts = () => {
    if (subcategory === 46 && allFavoriteProducts) {
      return allFavoriteProducts.map((product) => (
        <FavoritesProductsElement
          {...product}
          isChecked={selectedProducts.some((x) => x.productId === product.productId)}
          onCheckChanged={handleProductChecked}
          key={product.productId}
        />
      ));
    } else if (subcategory !== 46 && favorites) {
      return favorites.map((product) => (
        <FavoritesProductsElement
          {...product}
          isChecked={selectedProducts.some((x) => x.productId === product.productId)}
          onCheckChanged={handleProductChecked}
          key={product.productId}
        />
      ));
    }
    return null;
  };

  return (
    <div className={`pt-2 ${allSelectedProducts ? 'pb-32' : 'pb-6'}`}>
      {renderFavoritesProducts()}
      {selectedProducts.length !== 0 && (
        <FavoritesProductPopUp
          selectedProductsIds={selectedProducts.map((x) => x.productId)}
          priceSum={selectedProductsTotalPrice}
        />
      )}
    </div>
  );
};

export default FavoritesProductsList;
