import {useEffect} from 'react';
import {useSetRecoilState} from 'recoil';

import {isBottomTabBarHiddenAtom} from '../atoms/bottomTabBarAtom';

const useBottomBar = () => {
  const setShowBottomBar = useSetRecoilState(isBottomTabBarHiddenAtom);

  useEffect(() => {
    setShowBottomBar(true);

    return () => setShowBottomBar(false);
  }, [setShowBottomBar]);
};

export default useBottomBar;
