import {supabase} from '../supabaseClient';

const insertProductToFavorites = async (productId: number) => {
  const {error} = await supabase.rpc('insert_product_to_favorites', {
    product_id_input: productId,
  });
  if (error != null) {
    throw error.message;
  }
};

const removeProductFromFavorites = async (productId: number) => {
  const {error} = await supabase.rpc('remove_product_from_favorites', {
    product_id_input: productId,
  });
  if (error != null) {
    throw error.message;
  }
};

export default {
  insertProductToFavorites,
  removeProductFromFavorites,
};
