import React, {FC, useEffect, useState} from 'react';

import {IonButton, IonIcon, IonInput} from '@ionic/react';

import {send} from 'ionicons/icons';

type SendMessageInputProps = {
  onMessageSent: (message: string) => void;
};

export const SendMessageInput: FC<SendMessageInputProps> = ({onMessageSent}) => {
  const [input, setInput] = useState<string | null>();
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  useEffect(() => {
    if (input == '') {
      setIsEmpty(true);
      return;
    }
    setIsEmpty(false);
  }, [input]);

  const onButtonClick = () => {
    setIsEmpty(true);
    if (input!.trim() != '' && input != null) {
      onMessageSent(input!.trim());
      setInput(null);
      setIsEmpty(false);
    }
  };

  const isInputEnter = (event: any) => {
    if (event.key === 'Enter') {
      onButtonClick();
    }
  };

  return (
    <div className='flex justify-around items-center w-screen'>
      <div className='w-full px-2'>
        {isEmpty ? (
          <IonInput
            onIonInput={(e: any) => {
              setInput(e.target.value);
            }}
            value={input}
            placeholder='Message cannot be empty'
            className='bg-primary/40 rounded-xl border-2 border-pureRed h-9 tablet:h-12
            desktop:h-16 tablet:text-lg desktop:text-xl'
          >
            <IonIcon slot='start' />
          </IonInput>
        ) : (
          <IonInput
            value={input}
            placeholder='Textnachricht'
            onKeyDown={isInputEnter}
            onIonInput={(e: any) => setInput(e.target.value)}
            className='bg-primary/40 rounded-xl h-9 tablet:h-12 desktop:h-16
            tablet:text-lg desktop:text-xl'
          >
            <IonIcon slot='start' />
          </IonInput>
        )}
      </div>
      <div className='pr-2'>
        <IonButton
          mode='ios'
          onClick={onButtonClick}
          className='bg-primary rounded-xl text-white text-tiny h-9 w-9
          tablet:w-12 tablet:h-12 desktop:w-16 desktop:h-16'
        >
          <IonIcon slot='icon-only' icon={send} />
        </IonButton>
      </div>
    </div>
  );
};
