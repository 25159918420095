import {FC} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {IonContent, IonHeader, IonIcon, IonModal, IonPage, IonText} from '@ionic/react';

import {enterAnimation, leaveAnimation} from '../../animations/filterDetailModalAnimation';
import {appliedFiltersAtom} from '../../atoms/appliedFiltersAtom';
import {choosenFilterAtom} from '../../atoms/choosenFilterAtom';
import {isDetailModalOpenedAtom} from '../../atoms/filterModalAtom';
import {filtersTranslator} from '../../utils/filtersTranslator';
import AppToolbar from '../AppToolbar';
import IonLoadingSuspense from '../IonLoadingSuspense';
import FilterDetailOptions from './FilterDetailOptions';
import PriceFilterInputs from './PriceFilterInputs';

import {chevronBackOutline} from 'ionicons/icons';
import styles from './FilterModal.module.css';

const FilterDetailModal: FC = () => {
  const [isDetailModalOpened, setIsDetailModalOpened] = useRecoilState(isDetailModalOpenedAtom);
  const choosenFilter = useRecoilValue(choosenFilterAtom);
  const setAppliedFilters = useSetRecoilState(appliedFiltersAtom);

  const capitalizeFirst = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const hideDetailModal = () => {
    setIsDetailModalOpened(false);
  };

  const getModalContent = () => {
    if (choosenFilter === 'price') {
      return <PriceFilterInputs />;
    }

    return (
      <IonLoadingSuspense>
        <FilterDetailOptions type={choosenFilter!} />
      </IonLoadingSuspense>
    );
  };

  const handleClearFilter = () => {
    setAppliedFilters((prev) => ({
      ...prev,
      [choosenFilter as string]: undefined,
    }));
    setIsDetailModalOpened(false);
  };

  return (
    <IonModal
      mode='ios'
      id='filter-detail-modal'
      isOpen={isDetailModalOpened}
      enterAnimation={enterAnimation}
      leaveAnimation={leaveAnimation}
    >
      <IonPage>
        <IonHeader collapse='condense' className={styles.Header}>
          <AppToolbar
            leftButtonsCss='z-50'
            leftSlot1={
              <IonIcon
                onClick={hideDetailModal}
                className='text-4xl pl-4'
                icon={chevronBackOutline}
              />
            }
            titleText={capitalizeFirst(filtersTranslator(choosenFilter?.toString() ?? ''))}
            rightSlot1={
              <IonText onClick={handleClearFilter} className='text-l pr-5'>
                Löschen
              </IonText>
            }
          />
        </IonHeader>
        <IonContent scrollY={false} color='tertiary'>
          <div className='w-screen py-1 bg-tertiary' />
          {getModalContent()}
        </IonContent>
      </IonPage>
    </IonModal>
  );
};

export default FilterDetailModal;
