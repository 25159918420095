import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router';
import {useSetRecoilState} from 'recoil';

import {IonContent, IonPage, useIonToast} from '@ionic/react';

import {isBottomTabBarHiddenAtom} from '../../atoms/bottomTabBarAtom';
import Logo from '../../components/Logo';
import SetPasswordForm from '../../forms/SetPassword.form';
import useToast from '../../hooks/customToast';
import {SetPasswordData} from '../../interfaces/SetPasswordData';
import {supabase} from '../../supabaseClient';

const SetPassword = () => {
  const presentToast = useToast();
  const [presentIonToast] = useIonToast();
  const location = useLocation();
  const history = useHistory();

  const accessToken = new URLSearchParams(location.search).get('access_token');

  const setIsBottomTabBarHidden = useSetRecoilState(isBottomTabBarHiddenAtom);

  useEffect(() => {
    setIsBottomTabBarHidden(true);
    return () => {
      setIsBottomTabBarHidden(false);
    };
  }, []);

  if (!accessToken) {
    presentIonToast({
      message: 'Das Passwort-Wiederherstellungstoken ist abgelaufen',
      duration: 2000,
      color: 'warning',
      cssClass: 'text-center text-darkGreen',
    });
    history.push('/');
  }

  const onSubmit = async (data: SetPasswordData) => {
    const {error} = await supabase.auth.api.updateUser(accessToken!, {
      password: data.password,
    });

    if (error) {
      await presentToast(error.message, 'error');
      return;
    }
    await presentToast('Passwort erfolgreich zurückgesetzt');
    history.push('/login');
  };

  return (
    <IonPage>
      <IonContent>
        <div className='w-full h-screen flex flex-col justify-center'>
          <Logo />
          <SetPasswordForm onSubmit={onSubmit} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SetPassword;
