

import {IonContent, IonPage} from '@ionic/react';

import ButtonLink from '../../components/Buttons/ButtonLink';
import Logo from '../../components/Logo';

const RegisterPromptPage = () => {
  return (
    <IonPage>
      <IonContent>
        <div className='h-9/10 flex flex-col justify-center'>
          <Logo />
          <div className='w-9/12 mx-auto text-center'>
            <p className='items-center pt-10'>
              Wenn du bei more.moda etwas kaufen oder verkaufen möchtest, benötigen wir natürlich
              ein paar persönliche Daten von dir. Bitte registriere dich dafür.
            </p>
          </div>
          <div className='flex justify-center pt-16'>
            <ButtonLink text='OK' routerLink='register'></ButtonLink>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RegisterPromptPage;
