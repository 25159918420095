import {atom, selector} from 'recoil';

export const tmpCartActionsCount = atom({key: 'tempCartActionsCount', default: 0});

export const tmpCartProductsQuantity = atom({
  key: 'tmpCartProducts',
  default: 0,
});

export const tmpCartProductsQuantitySelector = selector({
  key: 'tmpCartProductsQuantity',
  get: ({get}) => get(getTmpCartSelector).length,
});

export const deleteTmpCartFromStorage = async () => {
  localStorage.removeItem('nonLoggedCart');
};

export const getTmpCartSelector = selector<any[]>({
  key: 'getTmpCartSelector',
  get: ({get}) => {
    get(tmpCartActionsCount);
    const nonLoggedCartString = localStorage.getItem('nonLoggedCart');

    if (nonLoggedCartString) return JSON.parse(nonLoggedCartString);

    return [];
  },
});
