import React, {FC} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {IonItem, IonLabel, IonList} from '@ionic/react';

import {appliedFiltersAtom} from '../../atoms/appliedFiltersAtom';
import {isDetailModalOpenedAtom} from '../../atoms/filterModalAtom';
import {filterSelector} from '../../selectors/sizesSelectors';

type FilterDetailOptionsProps = {
  type: 'price' | 'size' | 'color' | 'material';
};

const FilterDetailOptions: FC<FilterDetailOptionsProps> = ({type}) => {
  const elements = useRecoilValue(filterSelector(type));
  const setIsFilterDetailModuleOpened = useSetRecoilState(isDetailModalOpenedAtom);
  const appliedFilter = useSetRecoilState(appliedFiltersAtom);

  const handleOptionClick = (clickedElement: any) => {
    appliedFilter((prevValue) => ({...prevValue, [type]: clickedElement}));
    setIsFilterDetailModuleOpened(false);
  };

  return (
    <IonList lines='none' color='tertiary'>
      {elements &&
        elements.map((x) => (
          <IonItem
            onClick={() => handleOptionClick(x)}
            button
            detail={false}
            className='border-b-1 border-gray-500'
            key={x.id}
          >
            <IonLabel>{x.name}</IonLabel>
          </IonItem>
        ))}
    </IonList>
  );
};

export default FilterDetailOptions;
