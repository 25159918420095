import React, {ComponentProps, FC, useEffect, useState} from 'react';
import {useController} from 'react-hook-form';
import {useRecoilState} from 'recoil';

import {IonIcon, IonInput, IonItem, IonLabel, IonNote} from '@ionic/react';

import {previewProductDataAtom} from '../../atoms/previewProductDataAtom';
import {BaseInputProps} from '../../interfaces/BaseInputsProps';

import {eye, eyeOff} from 'ionicons/icons';

type TextInputProps = {
  label: string;
  type?: 'text' | 'password' | 'number';
  optional?: boolean;
  addOrEdit?: string;
  price?: boolean;
} & BaseInputProps &
  ComponentProps<typeof IonInput>;

const TextInput: FC<TextInputProps> = ({
  fieldName = '',
  type = 'text',
  maxlength,
  label,
  control,
  rules,
  optional,
  addOrEdit,
  price,
}: TextInputProps) => {
  const {
    field: {name, onChange, onBlur, ref, value},
    fieldState: {error},
  } = useController({
    control,
    name: fieldName,
    rules,
  });

  const [previewData, setPreviewData] = useRecoilState(previewProductDataAtom);
  const [checked, setChecked] = useState<boolean>(false);
  const [showPasswordIcon, setShowPasswordIcon] = useState(eye);
  const [inputType, setInputType] = useState(type);

  useEffect(() => {
    setPreviewData({...previewData, [name]: value});
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const onShowPasswordClicked = () => {
    if (showPasswordIcon == eye) {
      setShowPasswordIcon(eyeOff);
      setInputType('text');
    } else {
      setShowPasswordIcon(eye);
      setInputType('password');
    }
  };

  const calculateTransferPrice = (itemPrice: number) => {
    const STRIPE_MIN_FEE = 500;
    const STRIPE_FEE_PERCENTAGE = 10;

    const percentageFee = itemPrice * (STRIPE_FEE_PERCENTAGE / 100);

    if (percentageFee > STRIPE_MIN_FEE) return itemPrice * (1 - STRIPE_FEE_PERCENTAGE / 100);

    return itemPrice - STRIPE_MIN_FEE;
  };

  return (
    <div>
      {optional && addOrEdit === 'Speichern' && (
        <div className='flex justify-center'>
          <input type='checkbox' checked={checked} onChange={handleChange} />
          <p className='pl-2'>Füge einen Rabatt hinzu</p>
        </div>
      )}
      {(!optional || (optional && checked)) && (
        <IonItem
          lines='none'
          className='my-3 mx-auto w-11/12 tablet:w-2/3 desktop:w-2/5 bg-offWhite bg-opacity-25
        rounded-3xl ion-invalid'
        >
          <IonLabel position='floating'>{label}</IonLabel>
          <div className='flex space-between w-full'>
            <IonInput
              ref={ref}
              name={name}
              type={inputType}
              value={value}
              min={0}
              autocomplete='new-password'
              maxlength={maxlength}
              onIonBlur={onBlur}
              onIonChange={onChange}
            />
            {type === 'password' && (
              <IonIcon
                icon={showPasswordIcon}
                className='text-xl hover:cursor-pointer'
                onClick={onShowPasswordClicked}
              />
            )}
          </div>
          <IonNote slot='error' color='danger' className='text-sm tablet:text-base desktop:text-lg'>
            {error && error.message}
          </IonNote>
          {price && (
            <IonNote color='white'>
              {!error && value * 100 < 600
                ? 'Der Mindestpreis beträgt 6 Euro'
                : `Du bekommst ${calculateTransferPrice(value * 100) / 100}€`}
            </IonNote>
          )}
        </IonItem>
      )}
    </div>
  );
};

export default TextInput;
