

import {IonContent, IonPage, useIonRouter} from '@ionic/react';

import Logo from '../../components/Logo';
import SignUpText from '../../components/SignUpText';
import {useAuth} from '../../contexts/Auth';
import SignInForm from '../../forms/SignIn.form';
import useToast from '../../hooks/customToast';
import useBottomBar from '../../hooks/useBottomBar';
import {SignInData} from '../../interfaces/SignInData';

const Login = () => {
  useBottomBar();
  const {signIn} = useAuth();
  const presentToast = useToast();
  const router = useIonRouter();

  const onSubmit = async (loginData: SignInData) => {
    const {error} = await signIn(loginData);

    if (error) {
      if ((error.message = 'Invalid login credentials')) {
        presentToast('Ihre Login Daten sind leider falsch', 'error');
        return;
      }
      presentToast(error.message, 'error');
      return;
    }

    router.push('/dashboard');
    presentToast('Du wurdest erfolgreich eingeloggt!');
  };

  return (
    <IonPage>
      <IonContent>
        <div className='w-full h-screen flex flex-col justify-center'>
          <Logo />
          <SignInForm onSubmit={onSubmit} />
          <SignUpText />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
