

import {FormField} from '../interfaces/FormField';
import BIonForm from './BIonForm';
import Button from './Buttons/Button';
import ProductImageInput from './ImageInput/ProductImageInput';
import MultipleSelect from './SelectInput/MultipleSelect';
import Select from './SelectInput/Select';
import SelectCategories from './SelectInput/SelectCategories';
import TextInput from './TextInput/TextInput';

export const UIFields = ({
  sizesData,
  categoriesData,
  subCategoriesData,
  colorsData,
  materialsData,
  onSubmit,
  defaultValues,
  addOrEditLabel,
  previewClicked,
}: any) => {
  const fields: FormField[] = [
    {
      name: 'product_url',
      component: <ProductImageInput />,
    },
    {
      name: 'name',
      component: <TextInput label='Bezeichnung' />,
      options: {
        required: 'Dieses Feld ist erforderlich!',
      },
    },
    {
      name: 'sizes',
      component: <Select label='Größe' item={sizesData.data} />,
      options: {
        required: 'Dieses Feld ist erforderlich!',
      },
    },
    {
      name: 'category',
      component: <SelectCategories label='Kategorie' item={categoriesData.data} />,
      options: {
        required: 'Dieses Feld ist erforderlich!',
      },
    },
    {
      name: 'subcategory',
      component: <Select label='Unterkategorie' item={subCategoriesData} />,
      options: {
        required: 'Dieses Feld ist erforderlich!',
      },
    },
    {
      name: 'colors',
      component: <MultipleSelect label='Farbe' item={colorsData.data} />,
      options: {
        required: 'Dieses Feld ist erforderlich!',
      },
    },
    {
      name: 'materials',
      component: <MultipleSelect label='Material' item={materialsData.data} />,
      options: {
        required: 'Dieses Feld ist erforderlich!',
      },
    },
    {
      name: 'price',
      component: <TextInput label='Preis' type='number' price />,
      options: {
        required: 'Dieses Feld ist erforderlich!',
        min: {
          value: 6,
          message: 'Der Mindestpreis beträgt 6 Euro',
        },
      },
    },
    {
      name: 'description',
      component: <TextInput label='Beschreibung' type='text' />,
      options: {
        required: 'Dieses Feld ist erforderlich!',
      },
    },
    {
      name: 'sale_price',
      component: (
        <TextInput
          label='Herabgesetzter Preis'
          type='text'
          optional={true}
          addOrEdit={addOrEditLabel}
        />
      ),
    },
  ];

  return (
    <div className='py-w0'>
      <BIonForm fields={fields} onSubmit={onSubmit} defaultValues={defaultValues}>
        {addOrEditLabel === 'Hinzufügen' && (
          <Button type='button' text='Vorschau' onClick={previewClicked}
          className={`w-[80%] tablet:w-[55%] desktop:w-[25%] capitalize h-12
          bg-primary rounded-xl shadow-md text-white`} />
        )}
        <div className='my-2 mx-6' />
        <Button type='submit' text={addOrEditLabel}
        className={`w-[80%] tablet:w-[55%] desktop:w-[25%] capitalize h-12
        bg-primary rounded-xl shadow-md text-white`} />
      </BIonForm>
    </div>
  );
};
