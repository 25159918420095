import React, {FC} from 'react';

import BIonForm from '../components/BIonForm';
import TextInput from '../components/TextInput/TextInput';
import BaseFormProps from '../interfaces/BaseFormProps';
import {FormField} from '../interfaces/FormField';
import {SetPasswordData} from '../interfaces/SetPasswordData';
import regularExpressions from '../utils/regularExpressions';

const SetPasswordForm: FC<BaseFormProps<SetPasswordData>> = ({onSubmit}) => {
  const fields: FormField[] = [
    {
      name: 'password',
      component: <TextInput label='Neues Passwort' type='password' />,
      options: {
        required: 'This field is required!',
        pattern: {
          message: `Mindestens 8 Zeichen, mindestens ein Großbuchstabe und
          Kleinbuchstabe, eine Zahl und ein Sonderzeichen`,
          value: regularExpressions.password,
        },
      },
    },
  ];

  return (
    <BIonForm
      fields={fields}
      submitBtnText='Zurücksetzen'
      onSubmit={onSubmit}
      customCss='mx-auto w-full tablet:w-3/5 desktop:w-3/5'
    />
  );
};

export default SetPasswordForm;
