export const filtersTranslator = (name: string) => {
  switch (name) {
    case 'size':
      return 'Größe';
    case 'price':
      return 'Preis';
    case 'color':
      return 'Farbe';
    case 'material':
      return 'Material';
    default:
      return '';
  }
};
