import React, {FC, useEffect, useState} from 'react';
import {useSetRecoilState} from 'recoil';

import {IonContent, IonIcon, IonInput, IonPage} from '@ionic/react';

import {isBottomTabBarHiddenAtom} from '../../atoms/bottomTabBarAtom';
import {BackButton} from '../../components/Buttons/BackButton';
import IonLoadingSuspense from '../../components/IonLoadingSuspense';
import {Product} from '../../interfaces/Product';
import {supabase} from '../../supabaseClient';
import {SearchResult} from './Search.element';

import {search} from 'ionicons/icons';

const SearchPage: FC = () => {
  const [input, setInput] = useState<string>('');
  const [results, setResults] = useState<Product[]>([]);
  const setIsBottomTabBarHidden = useSetRecoilState(isBottomTabBarHiddenAtom);

  useEffect(() => {
    const ac = new AbortController();

    if (!input) {
      setResults([]);
      return;
    }

    const fetchResults = async () => {
      try {
        const {data, error} = await supabase
          .from('products')
          .select('id, name, price, user_id, owner_id(user_id, nick_name)')
          .ilike('name', `%${input}%`)
          .abortSignal(ac.signal);
        if (error != null) {
          throw error.message;
        }
        setResults(data);
      } catch {}
    };
    fetchResults();

    return () => {
      ac.abort();
    };
  }, [input]);

  useEffect(() => {
    setIsBottomTabBarHidden(true);

    return () => {
      setIsBottomTabBarHidden(false);
    };
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className='w-full h-full bg-darkGreen'>
          <div className='flex p-4 justify-center items-center'>
            <BackButton customCss='pr-3 mt-4' />
            <IonInput
              autofocus
              placeholder='Suchen'
              clearInput={true}
              typeof='search'
              inputmode='search'
              enterkeyhint='search'
              onIonInput={(e: any) => setInput(e.target.value)}
              onIonChange={() => setResults([])}
              className='w-11/12 h-8 flex-none bg-lightGreen border-1 rounded-xl
              tablet:h-10 desktop:w-1/3 desktop:h-12 mt-4'
            >
              <IonIcon icon={search} className='ml-2' />
            </IonInput>
          </div>
          <IonLoadingSuspense>
            <div className='w-full h-[90%] tablet:w-2/3 desktop:w-1/3 mx-auto'>
              {results.map((result: any) => (
                <SearchResult
                  key={result.id}
                  productId={result.id}
                  name={result.name}
                  owner={result.owner_id.nick_name}
                  price={result.price}
                  ownerId={result.owner_id?.user_id}
                />
              ))}
            </div>
          </IonLoadingSuspense>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SearchPage;
