import {useRecoilState} from 'recoil';

import {IonTabBar, IonTabButton} from '@ionic/react';

import {purchaseSalesTabAtom} from 'atoms/purchaseSalesTabAtom';

const PurchaseSalesTabs = () => {
  const [activeTab, setActiveTab] = useRecoilState(purchaseSalesTabAtom);

  return (
    <IonTabBar
      className='w-1/2 h-7 tablet:h-10 desktop:h-12 space-x-4 bg-transparent'
      onIonTabsWillChange={(e: any) => setActiveTab(e.detail.tab)}
    >
      <IonTabButton
        tab='sold'
        style={{'--color-selected': 'white'}}
        className={
          activeTab === 'sold'
            ? 'bg-lightGreen rounded-xl text-base'
            : 'bg-darkGreen rounded-xl text-lightGreen text-sm'
        }
      >
        Verkauft
      </IonTabButton>

      <IonTabButton
        tab='bought'
        style={{'--color-selected': 'white'}}
        className={
          activeTab === 'bought'
            ? 'bg-lightGreen rounded-xl text-base'
            : 'bg-darkGreen rounded-xl text-lightGreen text-sm'
        }
      >
        Gekauft
      </IonTabButton>
    </IonTabBar>
  );
};

export default PurchaseSalesTabs;
