import {lazy, useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {IonContent, IonHeader, IonPage} from '@ionic/react';
import {User} from '@supabase/supabase-js';

import {isBottomTabBarHiddenAtom} from 'atoms/bottomTabBarAtom';
import {purchaseSalesTabAtom} from 'atoms/purchaseSalesTabAtom';
import AppToolbar from 'components/AppToolbar';
import {BackButton} from 'components/Buttons/BackButton';
import {SearchButton} from 'components/Buttons/SearchButton';
import FilterDetailModal from 'components/Filter/FilterDetailModal';
import FilterModal from 'components/Filter/FIlterModal';
import IonLoadingSuspense from 'components/IonLoadingSuspense';
import {useAuth} from 'contexts/Auth';
import {BoughtProduct} from 'interfaces/BoughtProduct';
import {SoldProduct} from 'interfaces/SoldProduct';
import {getBoughtProductsSelector, getSoldProductsSelector} from 'selectors/purchaseSalesSelectors';
import PurchaseSalesTabs from './PurchaseSalesComponents/PurchaseSales.tabs';

const PurchaseSalesProduct = lazy(() => import('./PurchaseSalesComponents/PurchaseSales.element'));

const PurchaseSalesPage = () => {
  const {user} = useAuth();

  const activeTab = useRecoilValue(purchaseSalesTabAtom);
  const setIsBottomTabBarHidden = useSetRecoilState(isBottomTabBarHiddenAtom);

  useEffect(() => {
    setIsBottomTabBarHidden(true);
    return () => {
      setIsBottomTabBarHidden(false);
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar
          leftSlot1={<BackButton />}
          titleText='Geschichte'
          rightSlot1={<SearchButton />}
        />
      </IonHeader>
      <IonContent>
        <div className='flex justify-center pt-1'>
          <PurchaseSalesTabs />
        </div>

        <div className='mobile:-mt-1 pb-5'>
          <IonLoadingSuspense>
            {activeTab === 'bought' && <BoughtProductsList user={user} />}
            {activeTab === 'sold' && <SoldProductsList user={user} />}
            <FilterDetailModal />
            <FilterModal />
          </IonLoadingSuspense>
        </div>
      </IonContent>
    </IonPage>
  );
};

const BoughtProductsList = ({user}: {user: User | null}) => {
  const boughtProducts = useRecoilValue(getBoughtProductsSelector(user));

  return (
    <>
      {boughtProducts &&
        boughtProducts.map((product: BoughtProduct) => (
          <PurchaseSalesProduct key={product.id} boughtBar product={product} />
        ))}
    </>
  );
};

const SoldProductsList = ({user}: {user: User | null}) => {
  const soldProducts = useRecoilValue(getSoldProductsSelector(user));

  return (
    <>
      {soldProducts &&
        soldProducts.map((product: SoldProduct) => (
          <PurchaseSalesProduct key={product.id} soldBar product={product} />
        ))}
    </>
  );
};

export default PurchaseSalesPage;
