import {BottomTabButton} from '../../interfaces/BotttomTabButton';

import {heartOutline} from 'ionicons/icons';
import homeIcon from '../../static/BottomTabsBar/home_icon.svg';
import messagesIcon from '../../static/BottomTabsBar/messages_icon.svg';
import profilIcon from '../../static/BottomTabsBar/profil_icon.svg';
import saleIcon from '../../static/BottomTabsBar/sale_icon.svg';

const HOME_TAB = 'dashboard';
const FAVORITES_TAB = 'favorite-products';
const SALE_TAB = 'sale';
const MESSAGE_TAB = 'inbox';
const PROFILE_TAB = 'profiletab';

const BOTTOM_BAR_TABS = [
  {
    id: 1,
    tab: HOME_TAB,
    label: 'Home',
    icon: homeIcon,
  },
  {
    id: 2,
    tab: FAVORITES_TAB,
    label: 'Favoriten',
    icon: heartOutline,
  },
  {
    id: 3,
    tab: SALE_TAB,
    label: 'Aktionen',
    icon: saleIcon,
  },
  {
    id: 4,
    tab: PROFILE_TAB,
    label: 'Deals',
    icon: profilIcon,
  },
  {
    id: 5,
    tab: MESSAGE_TAB,
    label: 'Postfach',
    icon: messagesIcon,
  },
] as ReadonlyArray<BottomTabButton>;

export const bottomBarTabs = {
  HOME_TAB,
  FAVORITES_TAB,
  PROFILE_TAB,
  SALE_TAB,
  MESSAGE_TAB,
  BOTTOM_BAR_TABS,
};
