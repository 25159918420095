import './Button.css';

import React, {ComponentProps, FC} from 'react';

import {IonButton} from '@ionic/react';

type ButtonProps = {
  text: string;
} & ComponentProps<typeof IonButton>;

const Button: FC<ButtonProps> = (props) => {
  return (
    <IonButton
      fill='clear'
      className={`w-[80%] tablet:w-[55%] desktop:w-[35%]  capitalize h-12 bg-primary
      rounded-xl shadow-md text-white`}
      {...props}
    >
      <span className='text-white text-base'>{props.text}</span>
    </IonButton>
  );
};

export default Button;
