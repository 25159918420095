import {supabaseAdmin} from '../supabaseAdmin';
import {supabase} from '../supabaseClient';

const deleteUserProfile = async (userId: string) => {
  await supabase.rpc('delete_user_profile');

  await supabaseAdmin.auth.api.deleteUser(userId);
};

export default {deleteUserProfile};
