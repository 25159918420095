import './ProductImageInput.css';

import React, {FC, useEffect, useState} from 'react';
import {useController} from 'react-hook-form';
import {useRecoilState} from 'recoil';

import {Camera, CameraResultType, Photo} from '@capacitor/camera';
import {IonButton, IonIcon, useIonAlert} from '@ionic/react';

import {currentValueLengthImg} from '../../atoms/currentProductAtom';
import {imagesToDeleteAtom} from '../../atoms/imagesToDeleteAtom';
import {previewProductDataAtom} from '../../atoms/previewProductDataAtom';
import useToast from '../../hooks/customToast';
import {BaseInputProps} from '../../interfaces/BaseInputsProps';
import {ImageData} from '../../interfaces/ImageData';
import {NUMBER_OF_PRODUCT_PHOTOS} from '../../utils/constants';

import {addCircleOutline} from 'ionicons/icons';

type AvatarImageInputProps = BaseInputProps;

const ProductImageInput: FC<AvatarImageInputProps> = ({control, fieldName = '', rules}) => {
  const {
    field: {onChange, value},
  } = useController({name: fieldName, rules, control});
  const [previewData, setPreviewData] = useRecoilState(previewProductDataAtom);
  const [valueLength, setValueLength] = useRecoilState(currentValueLengthImg);
  const [addImagesToDelete, setAddImagesToDelete] = useRecoilState(imagesToDeleteAtom);

  const [, setValues] = useState<string[]>(value);

  const [presentAlert] = useIonAlert();
  const presentToast = useToast();

  useEffect(() => {
    if (valueLength == undefined) setValueLength(0);
  });

  useEffect(() => {
    setValueLength(value?.length);
    setValues(value);
    checkPermissions();

    setPreviewData({...previewData, imageUrl: value});
  }, [value]);

  const checkPermissions = () => Promise<PermissionStatus>;

  const base64ToFile = (base64: string): File => {
    const rawData = atob(base64);
    const bytes = new Array(rawData.length);
    for (let x = 0; x < rawData.length; x++) {
      bytes[x] = rawData.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);
    return new File([arr], `capture-${Date.now()}.jpeg`, {type: 'image/jpeg'});
  };

  const takePicture = async () => {
    if (valueLength == NUMBER_OF_PRODUCT_PHOTOS) {
      await presentToast('Sie haben das Limit der hochgeladenen Bilder erreicht!', 'warning');
      return;
    }

    const image: Photo = await Camera.getPhoto({
      quality: 90,
      resultType: CameraResultType.Base64,
    });

    const file = base64ToFile(image.base64String!);
    const fileExt = image.format;
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${fileName}`;

    if (fileExt != 'png' && fileExt != 'jpg' && fileExt != 'jpeg') {
      // eslint-disable-next-line quotes
      presentToast(
        'Das ausgewählte Bild wird nicht unterstützt, bitte wählen Sie .png oder .jpg',
        'error',
      );
      return;
    }

    if (value == null) {
      onChange([{file, fileExt, filePath}]);
      return;
    }

    onChange([...value, {file, fileExt, filePath}]);
  };

  const deleteImage = (imageUrl: string, numberId: number) => {
    setAddImagesToDelete([...addImagesToDelete, value[numberId][1]]);
    const temp = [...value];
    temp.splice(numberId, 1);
    presentToast('Bild erfolgreich gelöscht');
    setValues((prev: Array<string>) => [...prev, value]);
    setValueLength(valueLength - 1);
    onChange([...temp]);
  };

  return (
    <div>
      <div className='absolute mt-4 flex justify-center w-full text-sm text-gray-400'>
        {valueLength === 0 && 'Bis zu 10 Bilder hochladen'}
      </div>
      <div className='flex justify-center w-full mb-2'>
        <div
          className={`flex justify-center items-center flex-wrap w-11/12 tablet:w-2/3 desktop:w-2/5
          px-1.5 pt-1 pb-1 border-2 border-lightGreen rounded-xl ${
            valueLength === 0 && 'h-[88px]'
          }`}
        >
          {value &&
            value.map((file: ImageData, numberId: number) => {
              return (
                <div key={numberId} aria-live='polite' className='m-0.5'>
                  <img
                    key={numberId}
                    alt='Kein Bild'
                    className='w-20 h-18 rounded-2xl'
                    src={
                      file.file ? URL.createObjectURL(file.file) : URL.createObjectURL(file[0].data)
                    }
                    onClick={() =>
                      presentAlert({
                        header: 'Sind Sie sicher, dass Sie das Bild löschen wollen?',
                        mode: 'md',
                        cssClass: 'custom-alert',
                        buttons: [
                          {
                            text: 'Abbrechen',
                            cssClass: 'alert-button-cancel',
                          },
                          {
                            text: 'Löschen',
                            cssClass: 'alert-button-confirm',
                            handler: () => {
                              deleteImage(file.filePath, numberId);
                            },
                          },
                        ],
                      })
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className='flex justify-center'>
        <IonButton
          fill='clear'
          onClick={takePicture}
          className='w-46 h-10 text-[12px] flex justify-center items-center
          text-lightGreen border-2 border-lightGreen rounded-xl space-x-1'
        >
          <IonIcon icon={addCircleOutline} className='text-xl ' />
          <div>Foto hinzufügen</div>
        </IonButton>
      </div>
    </div>
  );
};

export default ProductImageInput;
