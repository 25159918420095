import {atom, selector} from 'recoil';

import {supabase} from '../supabaseClient';

export const messagesCountAtom = atom({key: 'messagesCount', default: 0});

export const getMessagesCount = selector({
  key: 'getMessagesCount',
  get: async () => {
    const {data, error} = await supabase.rpc('get_new_messages_count').single();

    if (error != null) {
      throw error.message;
    }

    return data;
  },
});

export const lastestMessageIdFromDbAtom = atom({key: 'lastestMessageIdFromDb', default: 0});
