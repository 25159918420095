import React, {FC, useEffect} from 'react';
import {useSetRecoilState} from 'recoil';

import {IonContent, IonHeader, IonPage} from '@ionic/react';

import {choosenSubcategoryIdAtom} from 'atoms/choosenSubCategoryIdAtom';
import AppToolbar from 'components/AppToolbar';
import {BackButton} from 'components/Buttons/BackButton';
import {CartButton} from 'components/Buttons/CartButton';
import FilterButton from 'components/Buttons/FilterButton';
import {SearchButton} from 'components/Buttons/SearchButton';
import FilterDetailModal from 'components/Filter/FilterDetailModal';
import FilterModal from 'components/Filter/FIlterModal';
import IonLoadingSuspense from 'components/IonLoadingSuspense';
import {MyProductsList} from './MyProducts.list';
import MyProductsHeaderSubcategories from './MyProductsHeaderSubcategories.element';

const MyProducts: FC = () => {
  const setChoosenSubCategoryId = useSetRecoilState(choosenSubcategoryIdAtom);

  useEffect(() => {
    return () => {
      setChoosenSubCategoryId(null);
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar
          leftSlot1={<BackButton />}
          leftSlot2={<FilterButton />}
          rightSlot1={<SearchButton />}
          rightSlot2={<CartButton />}
        />
        <MyProductsHeaderSubcategories />
      </IonHeader>
      <IonContent>
        <IonLoadingSuspense>
          <MyProductsList />
        </IonLoadingSuspense>
        <FilterDetailModal />
        <IonLoadingSuspense>
          <FilterModal />
        </IonLoadingSuspense>
      </IonContent>
    </IonPage>
  );
};

export default MyProducts;
