import React, {FC} from 'react';

import {IonContent, IonHeader, IonPage} from '@ionic/react';

import AppToolbar from '../../../components/AppToolbar';
import {BackButton} from '../../../components/Buttons/BackButton';
import {CartButton} from '../../../components/Buttons/CartButton';
import FilterButton from '../../../components/Buttons/FilterButton';
import {SearchButton} from '../../../components/Buttons/SearchButton';
import FilterDetailModal from '../../../components/Filter/FilterDetailModal';
import FilterModal from '../../../components/Filter/FIlterModal';
import HeaderSubCategories from '../../../components/HeaderSubCategories';
import IonLoadingSuspense from '../../../components/IonLoadingSuspense';
import {AllProductsList} from './AllProducts.list';

const AllProducts: FC = () => (
  <IonPage>
    <IonHeader>
      <AppToolbar
        leftSlot1={<BackButton />}
        leftSlot2={<FilterButton />}
        rightSlot1={<SearchButton />}
        rightSlot2={<CartButton />}
      />
      <HeaderSubCategories />
    </IonHeader>
    <IonContent>
      <IonLoadingSuspense>
        <AllProductsList />
      </IonLoadingSuspense>
      <FilterDetailModal />
      <IonLoadingSuspense>
        <FilterModal />
      </IonLoadingSuspense>
    </IonContent>
  </IonPage>
);

export default AllProducts;
