import React, {FC} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {IonIcon} from '@ionic/react';

import {cartActionsCount, cartProductsQuantitySelector} from '../../selectors/cartSelector';
import cartsService from '../../services/cartsService';

import {trashBin} from 'ionicons/icons';

type ShoppingCartDeleteAllButtonProps = {};

const ShoppingCartDeleteAllButton: FC<ShoppingCartDeleteAllButtonProps> = () => {
  const quantityOfProductsInCart = useRecoilValue(cartProductsQuantitySelector);
  const setCartActionsCount = useSetRecoilState(cartActionsCount);

  const handleDeleteAll = async () => {
    await cartsService.removeAllProductsFromCurrentUserCart();
    setCartActionsCount((prev) => prev + 1);
  };
  return (
    <>
      {quantityOfProductsInCart !== 0 && (
        <IonIcon
          icon={trashBin}
          className='text-pureRed text-2xl tablet:text-3xl desktop:text-4xl desktop:pr-4 pr-2'
          onClick={handleDeleteAll}
        />
      )}
    </>
  );
};

export default ShoppingCartDeleteAllButton;
