import React, {FC} from 'react';
import {useResetRecoilState, useSetRecoilState} from 'recoil';

import {IonText} from '@ionic/react';

import {appliedFiltersAtom} from '../../atoms/appliedFiltersAtom';
import {displayedFiltersAtom} from '../../atoms/displayedFiltersAtom';
import {filterDataAtom} from '../../atoms/filterDataAtom';
import {isFirstModalOpenedAtom} from '../../atoms/filterModalAtom';

const ClearFiltersButton: FC = () => {
  const setIsFirstModalOpened = useSetRecoilState(isFirstModalOpenedAtom);

  const resetAppliedFilters = useResetRecoilState(appliedFiltersAtom);
  const resetDisplayedFilters = useResetRecoilState(displayedFiltersAtom);
  const resetFilterData = useResetRecoilState(filterDataAtom);

  const handleClearFilters = () => {
    resetDisplayedFilters();
    resetAppliedFilters();
    resetFilterData();
    setIsFirstModalOpened(false);
  };

  return (
    <IonText onClick={handleClearFilters} className='text-l pr-5'>
      Löschen
    </IonText>
  );
};

export default ClearFiltersButton;
