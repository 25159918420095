import React, {FC, useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';

import {IonChip, IonLabel, IonToolbar} from '@ionic/react';

import {choosenCategoryIdAtom} from '../atoms/choosenCategoryIdAtom';
import {categoriesWithImagesSelector} from '../selectors/categoriesSelector';
import IonLoadingSuspense from './IonLoadingSuspense';

const HeaderCategoriesItems: FC = () => {
  const categories = useRecoilValue(categoriesWithImagesSelector);
  const [categoryId, setCategoryId] = useRecoilState(choosenCategoryIdAtom);

  useEffect(() => {
    if (categories == null || categories.length == 0) {
      setCategoryId(null);
      return;
    }

    setCategoryId(categories[0].id);
  }, []);

  const handleIonChipClick = (categoryId: number) => {
    setCategoryId(categoryId);
  };

  return (
    <div
      className='flex overflow-x-auto w-full py-1 tablet:py-1.5 tablet:px-2
        desktop:py-2 desktop:px-2'
    >
      {categories &&
        categories.map((x) => (
          <IonChip
            key={x.id}
            className='border-1 flex-shrink-0 border-white pointer-events-auto'
            onClick={() => handleIonChipClick(x.id)}
            disabled={categoryId !== x.id}
          >
            <IonLabel>{x.name}</IonLabel>
          </IonChip>
        ))}
    </div>
  );
};

const HeaderCategories: FC = () => {
  return (
    <IonToolbar color='primary' className='h-14'>
      <IonLoadingSuspense>
        <>
          <div className='w-full h-[1px] bg-white' />
          <HeaderCategoriesItems />
        </>
      </IonLoadingSuspense>
    </IonToolbar>
  );
};

export default HeaderCategories;
