import React from 'react';

import {IonContent, IonHeader, IonIcon, IonItem, IonList, IonPage} from '@ionic/react';

import AppToolbar from '../../components/AppToolbar';
import {BackButton} from '../../components/Buttons/BackButton';

import {chevronForward} from 'ionicons/icons';

type Props = {};

const LegalsPage: React.FC<Props> = ({}) => {
  const openURL = (url: string) => {
    window.open(url, '_blank');
  };

  const handleItemClick = (item: 'AGBs' | 'Impressum' | 'FAQ' | 'Datenschutzerklarung') => {
    switch (item) {
      case 'AGBs':
        openURL('https://www.moremoda.at/agbs/');
        break;
      case 'Impressum':
        openURL('https://www.moremoda.at/impressum/');
        break;
      case 'FAQ':
        openURL('https://www.moremoda.at/faqs/');
        break;
      case 'Datenschutzerklarung':
        openURL('https://www.moremoda.at/datenschutzerklarung/');
        break;
      default:
        break;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar leftSlot1={<BackButton />} titleText='Rechtsinformation' />
      </IonHeader>
      <IonContent>
        <div className='w-full tablet:w-2/3 desktop:w-1/2 mx-auto'>
          <IonList className='w-full cursor-pointer'>
            <IonItem onClick={() => handleItemClick('AGBs')} className='bg-primary text-white'>
              AGBs
              <IonIcon
                icon={chevronForward}
                slot='end'
                color='white'
                className='text-xl tablet:text-2xl desktop:text-3xl'
              />
            </IonItem>

            <IonItem onClick={() => handleItemClick('Impressum')} className='bg-primary text-white'>
              Impressum
              <IonIcon
                icon={chevronForward}
                slot='end'
                color='white'
                className='text-xl tablet:text-2xl desktop:text-3xl'
              />
            </IonItem>

            <IonItem onClick={() => handleItemClick('FAQ')} className='bg-primary text-white'>
              FAQ
              <IonIcon
                icon={chevronForward}
                slot='end'
                color='white'
                className='text-xl tablet:text-2xl desktop:text-3xl'
              />
            </IonItem>

            <IonItem
              onClick={() => handleItemClick('Datenschutzerklarung')}
              className='bg-primary text-white'
            >
              Datenschutzerklärung
              <IonIcon
                icon={chevronForward}
                slot='end'
                color='white'
                className='text-xl tablet:text-2xl desktop:text-3xl'
              />
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LegalsPage;
