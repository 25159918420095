import {useEffect} from 'react';
import {useSetRecoilState} from 'recoil';

import GridBox from 'components/GridBox';
import ProductCard from 'components/ProductCard';
import {Product} from 'interfaces/Product';
import {productsActionsCount} from 'selectors/productsSelectors';
import OnSaleProductCard from './SaleProducts/SaleProduct.element';

const ProductsList = ({products}: {products: Product[]}) => {
  const setProductsActionsCount = useSetRecoilState(productsActionsCount);

  useEffect(() => {
    setProductsActionsCount((prev) => prev + 1);
  }, []);

  return (
    <GridBox
      content={products?.map((x) => {
        if (x.isOnSale) {
          return <OnSaleProductCard {...x} key={x.productId} />;
        } else {
          return <ProductCard {...x} key={x.productId} />;
        }
      })}
    />
  );
};

export default ProductsList;
