import {atom} from 'recoil';

export type PreviewObject = {
  name?: 'string';
};

export type ProductPreview = {
  category: PreviewObject;
  colors: PreviewObject[];
  description: string;
  materials: PreviewObject[];
  name: string;
  price: number;
  sizes: PreviewObject[];
  subcategory: PreviewObject;
  imageUrl:
    | [
        {
          file: any;
          fileExt: string;
          filePath: string;
        },
      ]
    | null;
};

export const previewProductDataAtom = atom<ProductPreview>({
  key: 'previewProductData',
  default: {
    category: {},
    colors: [],
    description: '',
    materials: [],
    name: '',
    price: 0,
    sizes: [],
    subcategory: {},
    imageUrl: null,
  },
});
