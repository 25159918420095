import './AddProduct.page.css';

import React, {Suspense, useState} from 'react';
import {useRecoilValue} from 'recoil';

import {IonButton, IonContent, IonHeader, IonLoading, IonModal, IonPage} from '@ionic/react';

import {currentValueLengthImg} from '../../../atoms/currentProductAtom';
import AppToolbar from '../../../components/AppToolbar';
import {BackButton} from '../../../components/Buttons/BackButton';
import AddProductForm from '../../../forms/Products/AddProduct.form';
import useToast from '../../../hooks/customToast';
import {supabase} from '../../../supabaseClient';
import DetailsProductInfoPreview from '../DetailsProduct/DetailsProductInfoPreview.page';

const AddProduct = () => {
  const presentToast = useToast();
  const valueImg = useRecoilValue(currentValueLengthImg);
  const [isOpen, setIsOpen] = useState(false);

  const addProduct = async (data: any) => {
    if (data.product_url == undefined) {
      await presentToast('Sie müssen ein Foto hinzufügen', 'error');
      return;
    }
    if (valueImg === 0) {
      await presentToast('Sie müssen ein Foto hinzufügen', 'error');
      return;
    }

    const imagesPaths = data.product_url.map((x: any) => x.filePath);

    const {error} = await supabase.rpc('insert_product', {
      name_input: data.name,
      price_input: data.price,
      description_input: data.description,
      category_id: data.category,
      subcategory_id: data.subcategory,
      images_paths: imagesPaths,
      colors: data.colors,
      sizes: [data.sizes],
      materials: data.materials,
    });

    if (error) {
      await presentToast(error.message, 'error');
      return;
    }

    await Promise.all(
      data.product_url.map((x: any) =>
        supabase.storage.from('products').upload(x.filePath, x.file),
      ),
    );

    await presentToast('Produkt erfolgreich hinzugefügt!');
    history.back();
  };

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar leftSlot1={<BackButton />} titleText='Produkt hinzufügen' />
      </IonHeader>
      <IonContent>
        <Suspense fallback={<IonLoading isOpen={false} />}>
          <AddProductForm onPreviewClick={() => setIsOpen(true)} onSubmit={addProduct} />
          <IonModal mode='ios' isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
            <IonContent color='tertiary'>
              <DetailsProductInfoPreview />
              <IonButton
                shape='round'
                className='flex mx-auto items-center text-white w-11/12 tablet:w-2/3 desktop:w-2/5'
                onClick={() => setIsOpen(false)}
              >
                Vorschau schließen
              </IonButton>
            </IonContent>
          </IonModal>
        </Suspense>
      </IonContent>
    </IonPage>
  );
};
export default AddProduct;
