import {useRecoilValue} from 'recoil';

import {getOwnerProductsSelector} from '../../../selectors/productsSelectors';
import ProductsList from '../Products.list';

const AllSellerProductsList = () => {
    const products = useRecoilValue(getOwnerProductsSelector);

  return <ProductsList products={products!} />;
};

export default AllSellerProductsList;
