import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';

import {currentUserProfile} from '../atoms/currentUserProfileAtom';
import {useAuth} from '../contexts/Auth';
import paymentsService from '../services/paymentsService';
import Button from './Buttons/Button';

type CreateConnectedAccountButtonProps = {};

const CreateConnectedAccountButton: FC<CreateConnectedAccountButtonProps> = () => {
  const {user} = useAuth();
  const userProfile = useRecoilValue(currentUserProfile(user!.id));

  const handleCreateMerchantAccount = async () => {
    const account = await paymentsService.retrieveAccountLink(
      user?.email!,
      userProfile.first_name,
      userProfile.last_name,
      userProfile.stripe_account_id,
    );

    window.location.replace(account.url);
  };

  return (
    <>
      {!userProfile?.charges_enabled && (
        <Button
          className='w-[80%] tablet:w-[55%] desktop:w-[35%] normal-case h-12 bg-primary
      rounded-xl shadow-md text-white'
          onClick={handleCreateMerchantAccount}
          text='Händlerkonto erstellen'
        />
      )}
    </>
  );
};

export default CreateConnectedAccountButton;
