import React, {FC, Fragment, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {IonAvatar, IonContent, IonHeader, IonImg, IonLabel, IonPage, IonTitle} from '@ionic/react';

import {isBottomTabBarHiddenAtom} from 'atoms/bottomTabBarAtom';
import {deviceWidthType} from 'atoms/deviceWidthType';
import AppToolbar from 'components/AppToolbar';
import {BackButton} from 'components/Buttons/BackButton';
import {ConversationInterlocutorInfo} from 'components/Chat/ConversationInterlocutorInfo';
import {ConversationProductInfo} from 'components/Chat/ConversationProductInfo';
import {MessageItem} from 'components/Chat/Message';
import {SendMessageInput} from 'components/Chat/SendMessageInput';
import IonLoadingSuspense from 'components/IonLoadingSuspense';
import {ConversationProfilePreview} from 'interfaces/ConversationProfilePreview';
import {Message} from 'interfaces/Message';
import {lastestMessageIdFromDbAtom} from 'selectors/messagesSelectors';
import {messagesService} from 'services/messagesService';
import {supabase} from 'supabaseClient';
import {BASE_STORAGE_URL, SYSTEM_BOUGHT_MESSAGE} from 'utils/constants';

export interface ConversationParams {
  interlocutorId?: string;
  productId?: string;
}

export const Conversation: FC = () => {
  const {interlocutorId, productId} = useParams<ConversationParams>();

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const deviceWidth = useRecoilValue(deviceWidthType);
  const lastestMessageIdFromDb = useRecoilValue(lastestMessageIdFromDbAtom);
  const setIsBottomTabBarHidden = useSetRecoilState(isBottomTabBarHiddenAtom);

  const [height, setHeight] = useState<number>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [userData, setUserData] = useState<ConversationProfilePreview>();

  useEffect(() => {
    (async () => {
      const {data, error} = await supabase
        .from('profiles')
        .select('avatar_url, first_name, last_name')
        .eq('user_id', interlocutorId)
        .single();

      if (error) throw error.message;

      setUserData({
        avatarUrl: data.avatar_url && `${BASE_STORAGE_URL}/avatars/${data.avatar_url}`,
        fullName: `${data.first_name && data.first_name} ${data.last_name && data.last_name}`,
      });
    })();

    const calculateHeight = () => {
      if (window.innerWidth < 900) {
        setHeight(Math.trunc(window.innerHeight - 283));
      } else {
        setHeight(Math.trunc(window.innerHeight - 345));
      }
    };

    calculateHeight();
  }, []);

  useEffect(() => {
    setIsBottomTabBarHidden(true);
    return () => {
      setIsBottomTabBarHidden(false);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setMessages(await messagesService.getMessages(interlocutorId, Number(productId)));
      } catch (error: any) {
        throw error.message;
      } finally {
        scrollLastMessageIntoView();
      }
    })();
  }, [lastestMessageIdFromDb]);

  const sendMessage = async (message: string) => {
    await messagesService.sendMessage(interlocutorId!, Number(productId), message);
  };

  const scrollLastMessageIntoView = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <IonPage>
      <IonLoadingSuspense>
        <IonHeader>
          <AppToolbar
            leftSlot1={<BackButton />}
            centerChild={
              <>
                {deviceWidth === 'mobile' ? (
                  <IonTitle className='font-normal tablet:text-xl desktop:text-xl'>
                    {userData?.fullName}
                  </IonTitle>
                ) : (
                  <div
                    className='tablet:w-[350px] tablet:h-[72px] desktop:w-[400px] desktop:h-[92px]
                  mx-auto flex items-center bg-darkGreen rounded-full'
                  >
                    <IonAvatar
                      className='tablet:w-[70px] tablet:h-[66px] desktop:w-[90px] desktop:h-[82px]
                     ml-1.5'
                    >
                      <IonImg
                        alt='profile avatar'
                        src={userData?.avatarUrl}
                        className='flex-shrink-0 bg-contain bg-center bg-no-repeat'
                      />
                    </IonAvatar>
                    <IonLabel className='m-auto text-white tablet:text-xl desktop:text-xl'>
                      {userData?.fullName}
                    </IonLabel>
                  </div>
                )}
              </>
            }
          />
        </IonHeader>
        <IonContent scrollY={false} className='relative'>
          <ConversationProductInfo productId={productId!} />
          {deviceWidth === 'mobile' && (
            <ConversationInterlocutorInfo
              fullName={userData?.fullName}
              avatarUrl={userData?.avatarUrl}
            />
          )}
          <div style={{height}}>
            <div className='h-full overflow-y-auto'>
              {messages &&
                messages.map((x) => (
                  <Fragment key={x.id}>
                    {/* system message when buyer tryies to communicate with seller */}
                    {x.text === SYSTEM_BOUGHT_MESSAGE && x.sentByCurrentUser ? (
                      <div className='my-4 px-4'>
                        <div
                          className='w-fit mx-auto p-2 rounded-xl text-center text-sm mobile:text-xs
                          font-medium text-gray-800 bg-blue-300'
                        >
                          {SYSTEM_BOUGHT_MESSAGE}
                        </div>
                      </div>
                    ) : (
                      <MessageItem {...x} />
                    )}
                  </Fragment>
                ))}
              <div ref={messagesEndRef} />
            </div>
          </div>

          <div className='w-full flex absolute bottom-6'>
            <SendMessageInput onMessageSent={sendMessage} />
          </div>
        </IonContent>
      </IonLoadingSuspense>
    </IonPage>
  );
};
