

import {useAuth} from '../../contexts/Auth';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const Routes = () => {
  const {user} = useAuth();

  return (
    <>
      {user && <PrivateRoutes />}
      {!user && <PublicRoutes />}
    </>
  );
};

export default Routes;
