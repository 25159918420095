import React, {FC, useEffect} from 'react';

import {usePaynamentAuth} from './PaynamentState';

const PaymentFail: FC = () => {
  const {setPaynamentAuth} = usePaynamentAuth();

  useEffect(() => {
    setPaynamentAuth(); // Change the value from false to true
  }, []);

  return (
    <div className='text-center pt-28'>
      <p className='text-2xl text-white pb-4'>Bezahlung fehlgeschlagen!</p>
      <p>Versuchen Sie es erneut.</p>
    </div>
  );
};

export default PaymentFail;
