import React from 'react';

import {IonItem, IonMenuToggle} from '@ionic/react';

interface BurgerMenuItemProps {
  routerLink?: string;
  label: string;
  onClick?: () => void;
}

export const BurgerMenuMobileItem: React.FC<BurgerMenuItemProps> = ({
  routerLink,
  label,
  onClick,
}) => {
  return (
    <IonMenuToggle>
      <IonItem
        mode='ios'
        lines='none'
        className='text-base font-light'
        routerLink={routerLink}
        onClick={onClick}
      >
        {label}
      </IonItem>
    </IonMenuToggle>
  );
};
