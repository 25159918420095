import {Message} from 'interfaces/Message';

export const MessageItem = ({text, sentByCurrentUser, sentDate}: Message) => {
  const formatDate = () => {
    return new Date(sentDate).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div className='flex flex-col py-3 px-3'>
      <div
        className={`relative px-2 py-2 min-w-15/100 max-w-3/4 rounded-xl text-white
        text-sm tablet:text-base desktop:text-lg ${
          sentByCurrentUser ? 'self-end bg-primary' : 'self-start bg-gray-200 text-black'
        } `}
      >
        {text}
        <div
          className='absolute right-2 -bottom-4 tablet:-bottom-5 desktop:-bottom-6 self-end
          text-gray-300/50 text-xs tablet:text-sm desktop:text-base'
        >
          {formatDate()}
        </div>
      </div>
    </div>
  );
};
