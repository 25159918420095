export const base64ToFile = (base64: string): File => {
  const rawData = atob(base64);
  const bytes = new Array(rawData.length);
  for (let x = 0; x < rawData.length; x++) {
    bytes[x] = rawData.charCodeAt(x);
  }
  const arr = new Uint8Array(bytes);
  return new File([arr], `capture-${Date.now()}.jpeg`, {type: 'image/jpeg'});
};

export const urlToFile = async (url: string, format: string): Promise<File> => {
  try {
    const blob = await fetch(url).then((r) => r.blob());
    return new File([blob], `gallery-${Date.now()}.${format}`, {type: `image/${format}`});
  } catch (error) {
    throw error;
  }
};
