import React, {FC, useEffect} from 'react';
import {useSetRecoilState} from 'recoil';

import {IonContent, IonHeader, IonPage} from '@ionic/react';

import {choosenSubcategoryIdAtom} from '../../../atoms/choosenSubCategoryIdAtom';
import AppToolbar from '../../../components/AppToolbar';
import {BackButton} from '../../../components/Buttons/BackButton';
import {CartButton} from '../../../components/Buttons/CartButton';
import {SearchButton} from '../../../components/Buttons/SearchButton';
import FilterDetailModal from '../../../components/Filter/FilterDetailModal';
import FilterModal from '../../../components/Filter/FIlterModal';
import IonLoadingSuspense from '../../../components/IonLoadingSuspense';
import {FavoritesProductsList} from './FavoritesProducts.list';
import FavouritesProductsSubCategories from './FavouritesProductsSubCategories';

const FavoritesProducts: FC = () => {
  const setChoosenSubCategoryId = useSetRecoilState(choosenSubcategoryIdAtom);

  useEffect(() => {
    return () => {
      setChoosenSubCategoryId(null);
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <AppToolbar
          leftSlot1={<BackButton />}
          titleText='Meine Favoriten'
          rightSlot1={<SearchButton />}
          rightSlot2={<CartButton />}
        />
        <FavouritesProductsSubCategories />
      </IonHeader>
      <IonContent>
        <IonLoadingSuspense>
          <FavoritesProductsList />
        </IonLoadingSuspense>
        <FilterDetailModal />
        <IonLoadingSuspense>
          <FilterModal />
        </IonLoadingSuspense>
      </IonContent>
    </IonPage>
  );
};

export default FavoritesProducts;
