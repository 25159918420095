import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';

import {choosenSubcategoryIdAtom} from '../../../atoms/choosenSubCategoryIdAtom';
import {allProducts, allProductsByCategory} from '../../../selectors/productsSelectors';
import ProductsList from '../Products.list';

export const AllProductsList: FC = () => {
  const products = useRecoilValue(allProducts);
  const allCategoryProducts = useRecoilValue(allProductsByCategory);
  const chosenSubcategoryId = useRecoilValue(choosenSubcategoryIdAtom);

  const selectedProducts = chosenSubcategoryId === 0 ? allCategoryProducts : products;

  return <ProductsList products={selectedProducts!} />;
};
