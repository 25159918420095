import React, {FC, useState} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {IonButton, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow} from '@ionic/react';

import {filterDataAtom} from '../../atoms/filterDataAtom';
import {isDetailModalOpenedAtom} from '../../atoms/filterModalAtom';

const PriceFilterInputs: FC = () => {
  const [selectedFilters, setSelectedFilters] = useRecoilState(filterDataAtom);

  const setIsDetailModalOpened = useSetRecoilState(isDetailModalOpenedAtom);
  const [fromPrice, setFromPrice] = useState<number>(selectedFilters.price?.from!);
  const [toPrice, setToPrice] = useState<number>(selectedFilters.price?.to!);

  const handleChangePriceRange = () => {
    setSelectedFilters((prev) => ({...prev, price: {from: fromPrice, to: toPrice}}));
    setIsDetailModalOpened(false);
  };

  const buttonDisabled = fromPrice == null || toPrice == null;

  return (
    <IonGrid className='flex flex-col h-full justify-between'>
      <IonRow>
        <IonCol>
          <IonItem color='tertiary' lines='inset'>
            <IonLabel position='stacked'>Von</IonLabel>
            <IonInput
              type='number'
              value={fromPrice}
              onIonChange={(e) => setFromPrice(e.target.value as number)}
            />
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem color='tertiary' lines='inset'>
            <IonLabel position='stacked'>Bis</IonLabel>
            <IonInput
              type='number'
              value={toPrice}
              onIonChange={(e) => setToPrice(e.target.value as number)}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonButton
        disabled={buttonDisabled}
        onClick={handleChangePriceRange}
        className='w-full px-3 h-12 mb-8'
      >
        Speichern
      </IonButton>
    </IonGrid>
  );
};

export default PriceFilterInputs;
