import {CartProductData} from '../interfaces/CartProductData';
import {StripeLineItem} from '../interfaces/StripeLineItem';
import {supabase} from '../supabaseClient';

const retrieveAccountLink = async (
  email: string,
  firstName: string,
  lastName: string,
  stripeAccountId: string,
) => {
  const {data: url, error} = await supabase.functions.invoke('create-connected-account', {
    body: JSON.stringify({email, firstName, lastName, stripeAccountId}),
  });

  if (error) {
    throw error.message;
  }

  return url;
};

const retrieveCheckoutSessionId = async (email: string, cartProducts: CartProductData[]) => {
  const items = cartProducts.map((x) => {
    let itemToReturn = {
      amount: x.price * 100,
      description: x.description,
      name: x.name,
      quantity: 1,
      stripeOwnerId: x.stripeOwnerId,
    } as StripeLineItem;

    if (x.firstProductImageUrl != null) {
      itemToReturn = {...itemToReturn, imagesUrls: [x.firstProductImageUrl]};
    }

    return itemToReturn;
  });

  const {data, error: supabaseError} = await supabase.functions.invoke(
    'create-stripe-checkout-session',
    {
      body: JSON.stringify({email, lineItems: items}),
    },
  );

  if (supabaseError) {
    throw supabaseError.message;
  }

  const {id: sessionId} = data;

  return sessionId;
};

export default {
  retrieveCheckoutSessionId,
  retrieveAccountLink,
};
